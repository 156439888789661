import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { ExploreOutlined, StorefrontOutlined, KeyboardArrowUp } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { ReactComponent as RealEstateIcon } from '../../assets/images/house-chimney-floor-svgrepo-com.svg'
import { ReactComponent as MarketingIcon } from '../../assets/images/marketing-svgrepo-com.svg'
import { ReactComponent as StoreIcon } from '../../assets/images/store-svgrepo-com.svg'
import { ReactComponent as EComIcon } from '../../assets/images/online-shop-ecommerce-svgrepo-com.svg'
import { ReactComponent as ToolboxIcon } from '../../assets/images/toolbox-svgrepo-com.svg'
import { ReactComponent as HospitalityIcon } from '../../assets/images/hotel-svgrepo-com.svg'
import { ReactComponent as CheckBoxIcon } from '../../assets/images/check-circle-svgrepo-com.svg'

const BusinessEmp = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ textAlign: "center", mt: 4, p: 3, borderRadius: "8px" }}>
            {/* Section 1: Business Empowerment */}
            <Typography
                variant="h2"
                component="h2"
                sx={{
                    fontSize: { xs: "1.5rem", sm: "2rem" },
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: "#5c187e",
                    mb: 3,
                }}
            >
                Empower your business with the right AI solutions.
            </Typography>
            <hr style={{ border: "1px solid purple", width: "60%", margin: "20px auto" }} />
            <Grid container spacing={3} sx={{ mt: 2, mb: 6 }}>
                {[
                    {
                        icon: <CheckBoxIcon />,
                        title: "Made for SMBs.",
                        description: "Cutting-edge AI solutions tailored to fuel business innovation.",
                    },
                    {
                        icon: <CheckBoxIcon />,
                        title: "Integrated Effortlessly.",
                        description: "Manage and optimize all your AI tools from one seamless platform.",
                    },
                    {
                        icon: <CheckBoxIcon />,
                        title: "Boost Productivity.",
                        description: "Drive growth with solutions that maximize efficiency and results.",
                    },
                ].map((item, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                        <Box sx={{ color: "purple", textAlign: "center" }}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: "2rem",
                                    mb: 1,
                                    fontWeight: "bold",
                                    fontFamily: "Poppins",
                                    color: "#5c187e"
                                }}
                            >
                                {item.icon} {item.title}
                            </Typography>
                            <Typography variant="body1" sx={{ fontFamily: "Poppins", color: "#5c187e" }}>
                                {item.description}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <hr style={{ border: "1px solid purple", }} />

            <Typography
                variant="h2"
                component="h2"
                sx={{
                    fontSize: { xs: "1.5rem", sm: "2rem" },
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: "#5c187e",
                    mb: 3,
                }}
            >
                Industry-Specific AI Solutions
            </Typography>
            {/* <hr style={{ border: "1px solid purple", width: "60%", margin: "20px auto" }} /> */}
            <Grid container spacing={3} sx={{ mt: 2, mb: 4 }}>
                {[
                    {
                        icon: <RealEstateIcon />,
                        title: "Real Estate",
                        description: "Boost lead quality, predict property values, and streamline outreach. An AI marketplace empowers real estate SMBs with tools to close deals faster and maximize ROI.",
                    },
                    {
                        icon: <StoreIcon />,
                        title: "Retail",
                        description: "Optimize inventory, forecast trends, and personalize marketing. Leverage AI to make smarter decisions and thrive in a fast-changing market.",
                    },
                    {
                        icon: <MarketingIcon />,
                        title: "Digital Marketing",
                        description: "Achieve targeted campaigns with advanced audience insights. AI marketplaces help SMBs automate strategies, driving engagement and ROI effortlessly.",
                    },
                    {
                        icon: <HospitalityIcon />,
                        title: "Hospitality",
                        description: "Revolutionize guest experiences with AI-driven bookings, pricing, and communication. Reduce costs, boost loyalty, and stand out with tailored solutions.",
                    },
                    {
                        icon: <ToolboxIcon />,
                        title: "Home Services",
                        description: "Automate scheduling, dispatching, and client updates. AI-powered tools increase efficiency, reduce downtime, and build customer trust.",
                    },
                    {
                        icon: <EComIcon />,
                        title: "E-commerce",
                        description: "Drive sales with personalized recommendations and optimized customer journeys. Scale operations effortlessly with AI tools designed for SMB growth.",
                    },
                ].map((item, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                        <Box sx={{ color: "purple", textAlign: "center" }}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: "2rem",
                                    mb: 1,
                                    fontWeight: "bold",
                                    fontFamily: "Poppins",
                                    color: "#5c187e"
                                }}
                            >
                                {item.icon} {item.title}
                            </Typography>
                            <Typography variant="body1" sx={{ fontFamily: "Poppins", color: "#5c187e" }}>
                                {item.description}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            {/* <hr style={{ border: "1px solid purple" }} /> */}

            {/* Section 2: How Can We Help */}
            {/* <Box sx={{ mt: 6, textAlign: "center" }}>
                <Typography
                    variant="h2"
                    component="h2"
                    sx={{
                        fontSize: { xs: "1.8rem", sm: "2.5rem" },
                        fontFamily: "Poppins",
                        color: "#5c187e",
                        mb: 3,
                    }}
                >
                    How Can We Best Help You?
                </Typography>

            </Box> */}
        </Box>
    );
};

export default BusinessEmp;

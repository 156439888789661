import React, { useState, useRef, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ProfilePicture from '../../assets/images/profilePicture.png';
import OnlineIcon from '../../assets/images/online_icon.svg';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '../../assets/images/divider.png';
import DownloadIcon from '../../assets/images/download_icon.svg'
import Button from '@mui/material/Button';
import SendIcon from '../../assets/images/plane.svg'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Emoji from '@mui/icons-material/SentimentSatisfiedAlt';
import EmojiPicker from 'emoji-picker-react';
import { useMediaQuery } from "react-responsive";
import WindowChatBlank from '../WindowChatBlankState'
import MediaModal from './MediaModal';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { ACTIVE_NOW, SEND_MSG, TYPE_A_MSG } from '../../constants/text';
import axios from 'axios'
import { IP_ADD, LOCAL_HOST } from '../../constants/api';
import Lottie from 'lottie-react';
import animationData from "./typing.json";
import { useNotification } from '../../Context/NotificationContext';
import { useLocation, useNavigate } from "react-router-dom";
import { sendNotificationApi } from '../../Services/Notification';
import Avatar from '@mui/material/Avatar';


const cardStyle = {
    border: 'none',
    boxShadow: 'none',
    width: '100%',
};

const profilePictureContainerStyle = {
    position: 'relative',
};

const onlineIconStyle = {
    position: 'absolute',
    top: '0',
    marginLeft: '-0.85rem',
};

const nameStyle = {
    color: '#3F0065',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginLeft: '1.06rem',
};

const messageStyle = {
    color: '#8E8E8E',
    fontFamily: 'Poppins',
    fontSize: '0.625rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginLeft: '1.12rem',
};

const dividerStyle = {
    width: '100%',
    height: '2px',
    strokeWidth: '1px',
    stroke: '#F2F2F2',
};

const chatBoxStyle = {
    width: '100%',
    height: '5rem',
    borderRadius: '0.875rem',
    border: '1px solid #D7D7D7',
    background: '#EAEAEA',
    padding: '1rem',
    boxSizing: 'border-box',
    resize: 'none',
};

const messageContainerStyle = {
    width: '30%',
    marginBottom: '1rem',
    borderRadius: '1.3125rem 1.3125rem 1.3125rem 0rem',
    background: 'rgba(208, 208, 208, 0.19)',
    padding: '1rem',
    boxSizing: 'border-box',
    overflowWrap: 'break-word',
    color: '#7C7C7C',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
};

const messageContainerWrapperStyle = {
    height: 'calc(100vh - 270px)',
    overflowY: 'auto',
};
const sendButton = {
    width: '5.875rem',
    height: '2.5rem',
    borderRadius: '0.375rem',
    background: '#9D40B1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'Normal',
    textTransform: 'none',
    marginTop: '1.12rem',

};
const timeStyle = {
    color: '#6A6A6A',
    fontFamily: 'Poppins',
    fontSize: '0.625rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
};
const emojiPickerContainerStyle = {
    position: 'absolute',
    zIndex: 2,
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
};

const TokenExpired = (tokenDate) => {
    const convertedTime = new Date(tokenDate);
    const currentTime = new Date();
    if (currentTime >= convertedTime) {
        return true;
    }
    return false;
}

const ChatWindow = ({ sellerId,setListChatId, listChatId, firstName = 'Jennifer', lastName = 'Fritz', chatsCopy, setChatListClicked }) => {
    console.log('listChatId::: ', listChatId);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
    const [isAccessChatComplete, setIsAccessChatComplete] = useState(false);
    const [attachments, setAttachments] = useState();
    const [isExpired, setIsExpired] = useState(true);
    const [chatId, setChatId] = useState();
    const [loading, setLoading] = useState(false);
    const messageContainerRef = useRef(null);
    const userData = (localStorage.getItem('access-token'));
    const userId = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const [typing, setTyping] = useState(false);
    const [istyping, setIsTyping] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 500 });
    const isTab = useMediaQuery({ minWidth: 950 });
    const emojiPickerRef = useRef(null);
    const navigate = useNavigate();
    const listChatIdRef = useRef(listChatId);
    const chatsCopyRef = useRef(chatsCopy);
    const fetchAgainRef = useRef(false);
    const { avatarColor, fetchedDisplayPic, displayPicture, notification, notificationUpdated, setNotification, setNotificationUpdated, messages, setMessages, socket, setFetchAgain } = useNotification();
    const location = useLocation();



    const refreshtoken = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const token = localStorage.getItem('refresh-token');
                const response = await axios.post(`${IP_ADD}/api/auth/refresh-tokens`, {
                    refreshToken: token
                });

                if (response.status === 200) {
                    localStorage.setItem('access-token', response.data.response.response.tokens.access.token);
                    localStorage.setItem('refresh-token', response.data.response.response.tokens.refresh.token);
                    localStorage.setItem('access-token-expiry', response.data.response.response.tokens.access.expires);
                    localStorage.setItem('refresh-token-expiry', response.data.response.response.tokens.refresh.expires);
                    setIsExpired(false);

                    // Resolve the promise with the response data
                    resolve(response.data);
                } else {
                    // Reject the promise with an error message
                    reject(new Error('Token refresh failed.'));
                }
            } catch (error) {
                // Reject the promise with the error
                reject(error);
            }
        });
    };

    useEffect(() => {
        // Scroll to the bottom of the message container when a new message is added
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    }, [messages, istyping]);
    useEffect(() => {
        // Update the ref whenever listChatId changes
        listChatIdRef.current = listChatId;
    }, [listChatId]);
    useEffect(() => {
        // Update the ref whenever listChatId changes
        chatsCopyRef.current = chatsCopy;
    }, [chatsCopy]);

    useEffect(() => {
        if (listChatId) {
            setChatId(listChatId);
            setIsAccessChatComplete(true);
        } else if (sellerId) {
            accessChat();

            setFetchAgain(true);
        }
    }, [listChatId, sellerId]);

    useEffect(() => {
        socket.on("typing", () => setIsTyping(true));
        socket.on("stop typing", () => setIsTyping(false));
    }, []);

    useEffect(() => {
        // You can use fetchAgainRef.current in other parts of your code
        if (fetchAgainRef.current) {
            setFetchAgain(true);
            fetchAgainRef.current = false; // Reset it after using
        }
    }, [fetchAgainRef.current]);

    useEffect(() => {
        if (chatId) {
            fetchMessages(chatId);
        }
    }, [chatId]);

    const getFileType = (fileExtension) => {
        switch (fileExtension) {
            case 'pdf':
                return 'PDF';
            case 'docx':
                return 'DOCX';
            case 'csv':
                return 'CSV';
            case 'ppt':
                return 'PPT';
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'svg':
                return 'Image';
            case 'mp4':
                return 'Video'
            case 'zip':
                return 'Zip'
            default:
                return 'Unknown';
        }
    };

    const getBackgroundColor = (fileType) => {
        switch (fileType) {
            case 'pdf':
                return 'red';
            case 'docx':
                return 'blue';
            case 'csv':
                return 'green';
            case 'ppt':
                return 'yellow';
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'svg':
                return 'purple';
            default:
                return 'orange';
        }
    };

    const formatTimeInPST = (timestamp) => {
        const date = new Date(timestamp);
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's local timezone
        const localTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: userTimezone, // Use the user's local timezone
        });
        return localTime;
    };

    const accessChat = async () => {
        const userId = sellerId;
        try {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userData}`,
                },
            };
            const response = await axios.post(`${IP_ADD}/api/chats`, { userId }, config);
            const chatId = response.data.response._id;
            setChatId(chatId);
            setIsAccessChatComplete(true);
            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter' && (message.trim() !== '' || attachments)) {
            socket.emit("stop typing", chatId);
            const refreshExpiryToken = localStorage.getItem('refresh-token-expiry');
            const convertedTime = new Date(refreshExpiryToken);
            const currentTime = new Date();
            if (currentTime >= convertedTime) {
                localStorage.removeItem('access-token');
                localStorage.removeItem('refresh-token');
                localStorage.removeItem('access-token-expiry');
                localStorage.removeItem('refresh-token-expiry');
                localStorage.removeItem('Cornucopia-user');
                navigate('/login');
                return;
            }
            const accessExpiryToken = localStorage.getItem('access-token-expiry');
            if (TokenExpired(accessExpiryToken)) {
                try {
                    // Assuming refreshtoken returns a promise
                    await refreshtoken();
                } catch (refreshError) {
                    console.log('Error refreshing token', refreshError);
                    // Handle error if needed
                }
            }

            const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            setLoading(true);

            try {
                const userData = (localStorage.getItem('access-token'));
                const formData = new FormData();

                if (!message)
                    formData.append('content', 'Attachment');
                else
                    formData.append('content', message);

                formData.append('chatId', chatId);
                formData.append('attachments', attachments);
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${userData}`,
                    },
                };

                const { data } = await axios.post(
                    `${IP_ADD}/api/messages`,
                    formData,
                    config
                );

                if (socket && socket.connected) {
                    socket.emit('event', { type: 'message', data: data.response });
                }

                chatsCopyRef.current.forEach(async chat => {
                    if (chat._id === chatId) {
                        const recipientId = userId.response.role === 'buyer' ? chat.users[1].id : chat.users[0].id;

                        const notificationApiResult = await sendNotificationApi(
                            recipientId,
                            message,
                            userId.response.id,
                            "message"
                        );

                        if (!notificationApiResult.success) {
                            console.log("Error in notification", notificationApiResult.error);
                        }
                    }
                });

                setAttachments(null);
                setMessages([...messages, data.response]);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }

            setMessage('');
        }
    };


    const fetchMessages = async (chatid) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userData}`,
                },
            };

            const { data } = await axios.get(
                `${IP_ADD}/api/messages/${chatid}`,
                config
            );
            setMessages(data.response);
            socket.emit("join chat", chatid);
        } catch (error) {
            console.log(error)
        }
    };

    const handleSendClick = async (e) => {
        if (message.trim() !== '' || attachments) {
            socket.emit("stop typing", chatId);
            const refreshExpiryToken = localStorage.getItem('refresh-token-expiry');
            const convertedTime = new Date(refreshExpiryToken);
            const currentTime = new Date();
            if (currentTime >= convertedTime) {
                localStorage.removeItem('access-token');
                localStorage.removeItem('refresh-token');
                localStorage.removeItem('access-token-expiry');
                localStorage.removeItem('refresh-token-expiry');
                localStorage.removeItem('Cornucopia-user');
                navigate('/login');
                return;
            }
            const accessExpiryToken = localStorage.getItem('access-token-expiry');
            if (TokenExpired(accessExpiryToken)) {
                try {
                    // Assuming refreshtoken returns a promise
                    await refreshtoken();
                } catch (refreshError) {
                    console.log('Error refreshing token', refreshError);
                    // Handle error if needed
                }
            }

            const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            setLoading(true);

            try {
                const userData = (localStorage.getItem('access-token'));
                const formData = new FormData();

                if (!message)
                    formData.append('content', 'Attachment');
                else
                    formData.append('content', message);

                formData.append('chatId', chatId);
                formData.append('attachments', attachments);

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${userData}`,
                    },
                };

                const { data } = await axios.post(
                    `${IP_ADD}/api/messages`,
                    formData,
                    config
                );

                if (socket && socket.connected) {
                    socket.emit('event', { type: 'message', data: data.response });
                }

                chatsCopyRef.current.forEach(async chat => {
                    if (chat._id === chatId) {
                        const recipientId = userId.response.role === 'buyer' ? chat.users[1].id : chat.users[0].id;

                        // Use the sendNotificationApi function
                        const notificationApiResult = await sendNotificationApi(
                            recipientId,
                            message,
                            userId.response.id,
                            "message"
                        );

                        if (!notificationApiResult.success) {
                            console.log("Error in notification", notificationApiResult.error);
                        }
                    }
                });

                setAttachments(null);
                setMessages([...messages, data.response]);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }

            setMessage('');
        }
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
        setAttachments(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const fileDataUrl = event.target.result;
                const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                const newMessage = {
                    content: `${selectedFile.name}`,
                    timestamp,
                    attachment: fileDataUrl,
                };
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    useEffect(() => {
        const handleClick = (event) => {
            // Check if the click occurred inside the EmojiPicker
            if (emojiPickerRef.current && emojiPickerRef.current.contains(event.target)) {
                // Click occurred inside the EmojiPicker, do nothing
                return;
            }

            // Click occurred outside the EmojiPicker, hide it
            setEmojiPickerVisible(false);
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const toggleEmojiPicker = (event) => {
        event.stopPropagation();
        setEmojiPickerVisible(!emojiPickerVisible);
    };

    const handleEmojiClick = (emojiObject) => {
        event.stopPropagation();
        setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    };
    const handleEmojiPickerClick = (event) => {
        event.stopPropagation();
    };

    const handleClickBack = () => {
        setChatListClicked(false)
    }

    const handleDownload = (attachment, fileName) => {
        const anchor = document.createElement('a');
        anchor.href = attachment;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    const typingHandler = (e) => {
        setMessage(e.target.value);


        if (!typing) {
            setTyping(true);
            socket.emit("typing", chatId);
        }
        let lastTypingTime = new Date().getTime();
        var timerLength = 3000;
        setTimeout(() => {
            var timeNow = new Date().getTime();
            var timeDiff = timeNow - lastTypingTime;
            if (timeDiff >= timerLength && typing) {
                socket.emit("stop typing", chatId);
                setTyping(false);
            }
        }, timerLength);
    };
    const deleteChat = async () => {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${userData}`,
            },
          };
          
          await axios.delete(`${IP_ADD}/api/chats/${chatId}`, config);
          setChatListClicked(false);
          setFetchAgain(true);
          setListChatId(null)

        } catch (error) {
          console.log(error);
          if(error?.response?.data?.status==200){
            setChatListClicked(false);
            setFetchAgain(true);
            setListChatId(null)

          }
        }
      };
    return (
        <>
            {!listChatId ? (
                <WindowChatBlank />
            ) : (
                <Card style={{ ...cardStyle, maxWidth: '100%' }}>
                    <CardContent>
                        <div style={{ display: 'flex' }}>
                            <div style={profilePictureContainerStyle}>
                                {fetchedDisplayPic && (
                                    displayPicture ? (
                                        <img src={displayPicture} alt="Profile" style={{ width: '2.875rem', height: '2.875rem', borderRadius: '2.875rem' }} />
                                    ) : (
                                        <Avatar sx={{ bgcolor: avatarColor, fontSize: '0.8rem', width: '2.8rem', height: '2.7rem', borderRadius: '2.8rem' }}>
                                            {`${firstName[0]}${lastName[0]}`}
                                        </Avatar>
                                    )
                                )}

                                <img src={OnlineIcon} alt="Online" style={{ ...onlineIconStyle, marginLeft: displayPicture ? '-0.875rem' : '2rem' }} />
                            </div>
                            <div style={{ flexDirection: 'column' }}>
                                <div style={nameStyle}>{firstName} {lastName}</div>
                                <div style={messageStyle}>{ACTIVE_NOW}</div>
                            </div>
                            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                {!isTab && <ArrowBackIcon onClick={handleClickBack} style={{ marginRight: '1rem', cursor: 'pointer' }} />}
                                <DeleteIcon 
                                onClick={deleteChat}
                                style={{ 
                                    cursor: 'pointer',
                                    color: '#9D40B1',
                                    '&:hover': {
                                    color: '#7b3289'
                                    }
                                }}
                                />
                            </div>
                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 'auto' }}>
                                {!isTab && <ArrowBackIcon onClick={handleClickBack} />}
                                <SearchIcon style={{ marginLeft: '1rem' }} />
                                <SettingsIcon style={{ marginLeft: '1rem' }} />
                            </div> */}
                        </div>

                        <img style={dividerStyle} src={Divider} alt=" " />

                        <div style={{ ...messageContainerWrapperStyle, flex: 1 }} ref={messageContainerRef}>

                            {messages.map((msg, index,) => (
                                <div

                                    style={{
                                        display: 'flex',
                                        justifyContent: msg.sender.id != userId.response.id ? 'flex-start' : 'flex-end',
                                        marginBottom: '2rem',
                                    }}
                                    key={msg.timestamp}
                                >

                                    {msg.sender.id !== userId.response.id && (
                                        msg.sender.displayPicture ? (
                                            <img
                                                style={{ width: '2.875rem', height: '2.875rem', marginTop: 'auto', marginBottom: '1rem', borderRadius: '2rem' }}
                                                src={msg.sender.displayPicture}
                                                alt="Profile"
                                            />
                                        ) : (
                                            <Avatar
                                                sx={{ bgcolor: avatarColor, fontSize: '0.8rem', width: '2.875rem', height: '2.875rem', marginTop: 'auto', marginBottom: '1rem' }}
                                            >
                                                {`${msg.sender.firstName[0]}${msg.sender.lastName[0]}`}
                                            </Avatar>
                                        )
                                    )}
                                    {msg.sender.id === userId.response.id && (
                                        <div style={{ ...timeStyle, marginTop: 'auto', marginRight: '1.25rem' }}>{formatTimeInPST(msg.createdAt)}</div>
                                    )}
                                    <div
                                        style={{
                                            ...messageContainerStyle,
                                            maxWidth: '70%',
                                            width: 'fit-content',
                                            marginLeft: '1rem',
                                            marginRight: '1rem',
                                            borderRadius: msg.sender.id !== userId.response.id ? '1.3125rem 1.3125rem 1.3125rem 0rem' : '1.3125rem 1.3125rem 0rem 1.3125rem',
                                            background: msg.sender.id !== userId.response.id ? 'rgba(238, 177, 254, 0.19)' : 'rgba(208, 208, 208, 0.19)',
                                            color: msg.sender.id !== userId.response.id ? 'rgba(51, 0, 82, 0.60)' : '#7C7C7C',
                                            cursor:
                                                msg.attachments &&
                                                    msg.attachments.length > 0 &&
                                                    (msg.attachments[0].endsWith('.svg') ||
                                                        msg.attachments[0].endsWith('.png') ||
                                                        msg.attachments[0].endsWith('.jpg') ||
                                                        msg.attachments[0].endsWith('.jpeg') ||
                                                        msg.attachments[0].endsWith('.mp4'))
                                                    ? 'pointer'
                                                    : 'auto',
                                        }}
                                        onClick={() => {
                                            if (
                                                msg.attachments &&
                                                msg.attachments.length > 0 &&
                                                (msg.attachments[0].endsWith('.svg') ||
                                                    msg.attachments[0].endsWith('.png') ||
                                                    msg.attachments[0].endsWith('.jpg') ||
                                                    msg.attachments[0].endsWith('.jpeg'))
                                            ) {
                                                setSelectedMedia(msg.attachments[0]);
                                                setModalVisible(true);
                                            }
                                        }}
                                    >
                                        {msg.attachments && msg.attachments.length > 0 ? (
                                            msg.attachments[0].endsWith('.svg') || msg.attachments[0].endsWith('.png') || msg.attachments[0].endsWith('.jpg') || msg.attachments[0].endsWith('.jpeg') ? (
                                                <>
                                                    <div>{msg.content}</div>
                                                    <img
                                                        src={msg.attachments[0]}
                                                        alt={msg.content}
                                                        style={{
                                                            maxWidth: '200px', // Set a fixed maximum width for images
                                                            maxHeight: '200px', // Set a fixed maximum height for images
                                                            borderRadius: '0.5rem',
                                                        }}
                                                    />                                                </>
                                            ) : msg.attachments[0].endsWith('.mp4') ? (
                                                <>
                                                    <div>{msg.content}</div>
                                                    <video
                                                        controls
                                                        width="100%"
                                                        style={{
                                                            maxWidth: '200px', // Set a fixed maximum width for videos
                                                            maxHeight: '200px', // Set a fixed maximum height for videos
                                                            borderRadius: '0.5rem',
                                                        }}
                                                    >                                                        <source src={msg.attachments[0]} type="video/mp4" />
                                                        Your Browser Does Not Support Video
                                                    </video>
                                                </>
                                            ) : (
                                                <>
                                                    <div>{msg.content}</div>
                                                    <div
                                                        onClick={() => handleDownload(msg.attachments[0], msg.content)}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            borderRadius: '0.3125rem',
                                                            background: '#E9E9E9',
                                                            padding: '0.5rem',
                                                            margin: '0.5rem',
                                                            cursor: 'pointer',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <div style={{
                                                            borderRadius: '0.3125rem',
                                                            width: '23%',
                                                            height: '80%',
                                                            backgroundColor: getBackgroundColor(msg.attachments[0].split('.').pop().toLowerCase()),
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            marginRight: '0.5rem',
                                                            color: '#FFF',
                                                            fontFamily: 'Poppins',
                                                            fontSize: '0.5rem',
                                                            fontStyle: 'normal',
                                                            fontWeight: 500,
                                                        }}>
                                                            {getFileType(msg.attachments[0].split('.').pop().toLowerCase())}
                                                        </div>
                                                        <a
                                                            href={msg.attachments[0]}
                                                            download={msg.content}
                                                            style={{
                                                                textDecoration: 'none',
                                                                color: '#7C7C7C',
                                                                fontFamily: 'Poppins',
                                                                fontSize: '0.625rem',
                                                                fontStyle: 'normal',
                                                                fontWeight: 600,
                                                                lineHeight: 'normal',
                                                                display: 'block',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                flex: '1',
                                                                maxWidth: '20ch', // Set the maximum width to 5 characters
                                                            }}
                                                        >
                                                            {msg.attachments[0].split('attachments_')[1]}
                                                        </a>
                                                        <img
                                                            src={DownloadIcon}
                                                            style={{ marginLeft: 'auto', cursor: 'pointer' }}
                                                            onClick={() => handleDownload(msg.attachments[0], msg.content)}
                                                            alt="Download"
                                                        />
                                                    </div>

                                                </>
                                            )
                                        ) : (
                                            <span>{msg.content}</span>
                                        )}
                                    </div>

                                    {modalVisible && (
                                        <MediaModal
                                            isOpen={modalVisible}
                                            onRequestClose={() => setModalVisible(false)}
                                            mediaUrl={selectedMedia}
                                        />
                                    )}
                                    {msg.sender.id != userId.response.id && (
                                        <div style={{ ...timeStyle, marginTop: 'auto', marginLeft: '1.25rem' }}>{formatTimeInPST(msg.createdAt)}</div>
                                    )}
                                    {msg.sender.id === userId.response.id && (
                                        msg.sender.displayPicture ? (
                                            <img
                                                style={{ width: '2.875rem', height: '2.875rem', marginTop: 'auto', marginBottom: '1rem', borderRadius: '2rem', marginRight: '1rem' }}
                                                src={msg.sender.displayPicture}
                                                alt="Profile"
                                            />
                                        ) : (
                                            <Avatar
                                                sx={{ bgcolor: '#ad4ead', fontSize: '0.8rem', width: '2.875rem', height: '2.875rem', marginTop: 'auto', marginBottom: '1rem', marginRight: '1rem' }}
                                            >
                                                {`${msg.sender.firstName[0]}${msg.sender.lastName[0]}`}
                                            </Avatar>
                                        )
                                    )}
                                </div>
                            ))}
                            {istyping ? (
                                <div style={{ width: '70px', height: '90px' }}>
                                    <Lottie animationData={animationData} loop={true} />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <img style={{ ...dividerStyle, marginTop: '0.62rem' }} src={Divider} alt=" " />

                        {attachments && (
                            <div style={{ display: 'flex' }}>
                                <div style={{
                                    marginTop: '0.3rem',
                                    borderRadius: '0.3125rem',
                                    width: '2rem',
                                    height: '1rem',
                                    backgroundColor: getBackgroundColor(attachments.name.split('.').pop().toLowerCase()),
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '0.5rem',
                                    color: '#FFF',
                                    fontFamily: 'Poppins',
                                    fontSize: '0.5rem',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                }}>
                                    {getFileType(attachments.name.split('.').pop().toLowerCase())}
                                </div>
                                <div style={{ width: '15rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    {attachments.name}
                                </div>
                                <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setAttachments(null)}><DeleteIcon /></div>
                            </div>
                        )}


                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {!modalVisible && (
                                <div style={{ ...emojiPickerContainerStyle, marginTop: '1.2rem', marginRight: '0.75rem', position: 'relative' }}>

                                    {emojiPickerVisible && (
                                        <div onClick={handleEmojiPickerClick}>
                                            <EmojiPicker
                                                id="emoji-picker"
                                                ref={emojiPickerRef}
                                                onEmojiClick={handleEmojiClick}
                                                disableSearchBar
                                                disableSkinTonePicker
                                                disableGroupPicker
                                                emojiSize={24}
                                                pickerStyle={{

                                                    top: 0,

                                                }}
                                            />
                                        </div>
                                    )}
                                    <Emoji onClick={toggleEmojiPicker} />
                                </div>
                            )}

                            <label style={{ marginTop: '1.25rem', cursor: 'pointer', transform: 'rotate(45deg)' }}>
                                <AttachFileIcon />
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileSelect(e)}
                                />
                            </label>
                        </div>
                        <textarea
                            value={message}
                            onChange={typingHandler}
                            onKeyPress={handleKeyPress}
                            style={{
                                ...chatBoxStyle,
                                border: 'none',
                                outline: 'none',
                            }}
                            placeholder={TYPE_A_MSG}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button style={sendButton} variant="contained" onClick={handleSendClick} >
                                {loading ? (
                                    <CircularProgress style={{ width: '27px', height: '26px', 'color': 'white' }} />
                                ) : (
                                    <>
                                        <img src={SendIcon} alt=" " />
                                        {SEND_MSG}
                                    </>
                                )}
                            </Button>
                        </div>
                    </CardContent>
                </Card >
            )}
        </>
    );
};

export default ChatWindow;

import ProductOne from '../assets/images/productImgOne.png'
import ProductTwo from '../assets/images/productImgTwo.png'
import ProductThree from '../assets/images/productImgThree.png'
import StraightIcon from '@mui/icons-material/North'
import DownIcon from '@mui/icons-material/South'

export const sellerData = [
  { name: 'John Doe', designation: 'Web Developer' },
  { name: 'Jane Smith', designation: 'UI/UX Designer' },
  { name: 'Michael Johnson', designation: 'DevOps' },
  { name: 'Emily Williams', designation: 'Web Developer' },
  { name: 'Robert Brown', designation: 'Web Developer' },
  { name: 'Jessica Davis', designation: 'UI/UX Designer' },
  { name: 'William Wilson', designation: 'Web Developer' },
  { name: 'Olivia Taylor', designation: 'UI/UX Designer' },
  { name: 'James Lee', designation: 'DevOps' },
  { name: 'Sophia Martinez', designation: 'UI/UX Designer' },
  { name: 'David Anderson', designation: 'DevOps' },
  { name: 'Emma Hernandez', designation: 'UI/UX Designer' },
  { name: 'Daniel Walker', designation: 'Web Developer' },
  { name: 'Ava White', designation: 'DevOps' },
  { name: 'Liam Scott', designation: 'Web Developer' },
  { name: 'Mia Garcia', designation: 'UI/UX Designer' },
  { name: 'Noah Turner', designation: 'Web Developer' },
  { name: 'Sophie Hall', designation: 'UI/UX Designer' },
  { name: 'Ethan Martinez', designation: 'DevOps' },
  { name: 'Isabella Adams', designation: 'UI/UX Designer' },
  { name: 'Jackson Wright', designation: 'Web Developer' },
  { name: 'Avery Green', designation: 'DevOps' },
  { name: 'Lucas King', designation: 'Web Developer' },
  { name: 'Luna Perez', designation: 'UI/UX Designer' },
  { name: 'Alexander Scott', designation: 'DevOps' },
  { name: 'Chloe Mitchell', designation: 'UI/UX Designer' },
  { name: 'Henry Rodriguez', designation: 'Web Developer' },
  { name: 'Zoe Cooper', designation: 'UI/UX Designer' },
  { name: 'Sebastian Ramirez', designation: 'DevOps' },
  { name: 'Nora Jenkins', designation: 'UI/UX Designer' }
]
export const productsData = [
  { name: 'DataGuard Pro', price: 149 },
  { name: 'QuickSync Manager', price: 59 },
  { name: 'SecureLink Enterprise', price: 299 },
  { name: 'CodeFlow Analytics', price: 'Free' },
  { name: 'AgileVision Planner', price: 129 },
  { name: 'MediaFuse Studio', price: 89 },
  { name: 'FusionWare Navigator', price: 179 },
  { name: 'TaskSync Express', price: 49 },
  { name: 'WebGuard Sentinel', price: 'Free' },
  { name: 'CloudWorks Sync', price: 69 },
  { name: 'NetworkNova Analyzer', price: 159 },
  { name: 'SwiftSync Pro', price: 99 },
  { name: 'AppDefender Shield', price: 'Free' },
  { name: 'RapidFlow Optimizer', price: 249 },
  { name: 'InfiniSync Lite', price: 'Free' },
  { name: 'CyberGuard Suite', price: 199 }
]
export const SellerProductData = [
  {
    id: '1',
    productImg: ProductOne,
    title: 'Business Essentials',
    productID: '#A86DZds1',
    category: 'Business',
    url: 'www.product1.com',
    sales: '1563',
    salesColor: '#00A44B',
    status: 'Activated',
    price: '$999',
    icon: (
      <StraightIcon
        color='#00A44B'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  },
  {
    id: '2',
    productImg: ProductTwo,
    title: 'Business Accelerator',
    productID: '#A86DZds2',
    category: 'Business',
    url: 'www.product2.com',
    sales: '2127',
    salesColor: '#00A44B',
    status: 'Activated',
    price: '$799',
    icon: (
      <StraightIcon
        color='#00A44B'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  },
  {
    id: '3',
    productImg: ProductThree,
    title: 'Business Masterclass',
    productID: '#A86DZds3',
    category: 'Business',
    url: 'www.product3.com',
    sales: '23',
    salesColor: '#D95656',
    status: 'Blocked',
    price: '$249',
    icon: (
      <DownIcon
        color='#D95656'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#EED6D6',
    statusTextColor: '#D95656'
  },
  {
    id: '4',
    productImg: ProductOne,
    title: 'Analytics Pro',
    productID: '#A86DZds4',
    category: 'Business',
    url: 'www.product4.com',
    sales: '1894',
    salesColor: '#00A44B',
    status: 'Activated',
    price: '$499',
    icon: (
      <StraightIcon
        color='#00A44B'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  },
  {
    id: '5',
    productImg: ProductTwo,
    title: 'Finance Pro',
    productID: '#A86DZds5',
    category: 'Business',
    url: 'www.product5.com',
    sales: '754',
    salesColor: '#C28C00',
    status: 'Blocked',
    price: '$399',
    icon: (
      <StraightIcon
        color='#C28C00'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#EED6D6',
    statusTextColor: '#D95656'
  },
  {
    id: '6',
    productImg: ProductThree,
    title: 'Marketing Masterclass',
    productID: '#A86DZds6',
    category: 'Business',
    url: 'www.product6.com',
    sales: '123',
    salesColor: '#D95656',
    status: 'Activated',
    price: '$599',
    icon: (
      <DownIcon
        color='#D95656'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  },
  {
    id: '7',
    productImg: ProductOne,
    title: 'Business Workshop',
    productID: '#A86DZds7',
    category: 'Business',
    url: 'www.product7.com',
    sales: '856',
    salesColor: '#00A44B',
    status: 'Activated',
    price: '$799',
    icon: (
      <StraightIcon
        color='#00A44B'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  },
  {
    id: '8',
    productImg: ProductTwo,
    title: 'Business Toolkit',
    productID: '#A86DZds8',
    category: 'Business',
    url: 'www.product8.com',
    sales: '632',
    salesColor: '#00A44B',
    status: 'Activated',
    price: '$599',
    icon: (
      <StraightIcon
        color='#00A44B'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  },
  {
    id: '9',
    productImg: ProductThree,
    title: 'Leadership Seminar',
    productID: '#A86DZds9',
    category: 'Business',
    url: 'www.product9.com',
    sales: '45',
    salesColor: '#D95656',
    status: 'Blocked',
    price: '$349',
    icon: (
      <DownIcon
        color='#D95656'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#EED6D6',
    statusTextColor: '#D95656'
  },
  {
    id: '10',
    productImg: ProductOne,
    title: 'Consulting Services',
    productID: '#A86DZds10',
    category: 'Business',
    url: 'www.product10.com',
    sales: '985',
    salesColor: '#00A44B',
    status: 'Activated',
    price: '$899',
    icon: (
      <StraightIcon
        color='#00A44B'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  },
  {
    id: '11',
    productImg: ProductTwo,
    title: 'Networking Workshop',
    productID: '#A86DZds11',
    category: 'Business',
    url: 'www.product11.com',
    sales: '732',
    salesColor: '#00A44B',
    status: 'Activated',
    price: '$499',
    icon: (
      <StraightIcon
        color='#00A44B'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  },
  {
    id: '12',
    productImg: ProductThree,
    title: 'Leadership Retreat',
    productID: '#A86DZds12',
    category: 'Business',
    url: 'www.product12.com',
    sales: '19',
    salesColor: '#D95656',
    status: 'Activated',
    price: '$799',
    icon: (
      <DownIcon
        color='#D95656'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  },
  {
    id: '13',
    productImg: ProductOne,
    title: 'Business Toolkit',
    productID: '#A86DZds13',
    category: 'Business',
    url: 'www.product13.com',
    sales: '564',
    salesColor: '#00A44B',
    status: 'Activated',
    price: '$699',
    icon: (
      <StraightIcon
        color='#00A44B'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  },
  {
    id: '14',
    productImg: ProductTwo,
    title: 'Business Masterclass',
    productID: '#A86DZds14',
    category: 'Business',
    url: 'www.product14.com',
    sales: '289',
    salesColor: '#C28C00',
    status: 'Blocked',
    price: '$299',
    icon: (
      <StraightIcon
        color='#C28C00'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#EED6D6',
    statusTextColor: '#D95656'
  },
  {
    id: '15',
    productImg: ProductThree,
    title: 'Analytics Pro',
    productID: '#A86DZds15',
    category: 'Business',
    url: 'www.product15.com',
    sales: '874',
    salesColor: '#00A44B',
    status: 'Activated',
    price: '$899',
    icon: (
      <StraightIcon
        color='#00A44B'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  },
  {
    id: '16',
    productImg: ProductOne,
    title: 'Business Training',
    productID: '#A86DZds16',
    category: 'Business',
    url: 'www.product16.com',
    sales: '368',
    salesColor: '#00A44B',
    status: 'Activated',
    price: '$699',
    icon: (
      <StraightIcon
        color='#00A44B'
        sx={{
          width: '0.75rem',
          height: '0.8125rem',
          margin: '3px 5px 0px 5px'
        }}
      />
    ),
    statusColor: '#D6EEDD',
    statusTextColor: '#00976A'
  }
]
export const SIGNUP_HEADER_DATA = [
  {
    id: '1',
    name: 'Home',
    margin_right: '2rem',
    navigation_url: '/'
  },
  {
    id: '2',
    name: 'Marketplace',
    margin_right: '2rem',
    navigation_url: '/products'
  }
  // {
  //   id: '3',
  //   name: 'Sellers',
  //   margin_right: '0rem',
  //   navigation_url: '/sellers'
  // }
]

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export const nameRegex = /^[a-zA-Z]+$/

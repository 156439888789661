// import React, { useState } from 'react';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import ClearIcon from '@mui/icons-material/Clear';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// import UploadFileIcon from '@mui/icons-material/UploadFile';

// const cardStyle = {
//     width: '100%',
//     height: '8.875rem',
//     borderRadius: '0.4375rem',
//     border: '1px dashed #9D40B1',
//     background: '#FAFAFA',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     cursor: 'pointer',
// };

// const textStyles = {
//     color: '#9D40B1',
//     textAlign: 'center',
//     fontFamily: 'Poppins',
//     fontSize: '0.75rem',
//     fontWeight: 500,
//     lineHeight: 'normal',
// };

// const iconStyle = {
//     width: '1.1875rem',
//     height: '1.1875rem',
//     marginBottom: '0.25rem',
//     color: '#9D40B1'
// };

// const fileInfoStyle = {
//     display: 'flex', 
//     alignItems: 'center',
//     gap: '1rem',
//     color: '#333',
//     fontFamily: 'Poppins',
//     fontSize: '0.875rem'
// };

// const fileIconStyle = {
//     color: '#9D40B1',
//     width: '2rem',
//     height: '2rem'
// };

// const ImplementationGuideUpload = ({ selectedGuide, setSelectedGuide }) => {
//     console.log('selectedGuide::: ', selectedGuide);
//     const handleFileSelect = (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             const validTypes = [
//                 'application/pdf', 
//                 'text/plain', 
//                 'application/msword',
//                 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//                 'text/markdown'
//             ];
            
//             if (validTypes.includes(file.type)) {
//                 setSelectedGuide(file);
//             } else {
//                 alert('Please upload a valid document file (PDF, DOC, DOCX, TXT, MD)');
//             }
//         }
//     };

//     const clearSelectedFile = () => {
//         setSelectedGuide(null);
//     };

//     return (
//         <Card elevation={0} style={cardStyle}>
//             <CardContent style={{ display: 'flex', alignItems: 'center' }}>
//                 {selectedGuide ? (
//                     <div style={fileInfoStyle}>
//                         <InsertDriveFileIcon style={fileIconStyle} />
//                         <div style={{ maxWidth: '20rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>
//                             {selectedGuide.name}
//                             <div style={{ color: '#666', fontSize: '0.75rem' }}>
//                                 {(selectedGuide.size / 1024 / 1024).toFixed(2)} MB
//                             </div>
//                         </div>
//                         <ClearIcon 
//                             onClick={clearSelectedFile} 
//                             style={{ marginLeft: '3rem', cursor: 'pointer', color: '#666' }} 
//                         />
//                     </div>
//                 ) : (
//                     <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
//                         <input
//                             type="file"
//                             accept=".pdf,.doc,.docx,.txt,.md"
//                             style={{ display: 'none' }}
//                             onChange={handleFileSelect}
//                         />
//                         <UploadFileIcon style={iconStyle} />
//                         <div style={textStyles}>Click to upload</div>
//                         <div style={{ ...textStyles, color: '#888', marginTop: '0' }}>or drag and drop</div>
//                         <div style={{ ...textStyles, color: '#888', fontSize: '0.625rem', marginTop: '0.5rem' }}>
//                             Supported: PDF, DOC, DOCX, TXT, MD
//                         </div>
//                     </label>
//                 )}
//             </CardContent>
//         </Card>
//     );
// };

// export default ImplementationGuideUpload;
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClearIcon from '@mui/icons-material/Clear';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LinkIcon from '@mui/icons-material/Link';

const cardStyle = {
    width: '100%',
    height: '8.875rem',
    borderRadius: '0.4375rem',
    border: '1px dashed #9D40B1',
    background: '#FAFAFA',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
};

const textStyles = {
    color: '#9D40B1',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 'normal',
};

const iconStyle = {
    width: '1.1875rem',
    height: '1.1875rem',
    marginBottom: '0.25rem',
    color: '#9D40B1',
};

const fileInfoStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    color: '#333',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
};

const fileIconStyle = {
    color: '#9D40B1',
    width: '2rem',
    height: '2rem',
};

const ImplementationGuideUpload = ({ selectedGuide, setSelectedGuide }) => {
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const validTypes = [
                'application/pdf',
                'text/plain',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'text/markdown',
            ];

            if (validTypes.includes(file.type)) {
                setSelectedGuide(file);
            } else {
                alert('Please upload a valid document file (PDF, DOC, DOCX, TXT, MD)');
            }
        }
    };

    const clearSelectedGuide = () => {
        setSelectedGuide(null);
    };

    const isLink = (guide) => typeof guide === 'string' && guide.startsWith('http');

    return (
        <Card elevation={0} style={cardStyle}>
            <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                {selectedGuide ? (
                    isLink(selectedGuide) ? (
                        <div style={fileInfoStyle}>
                            <LinkIcon style={fileIconStyle} />
                            <div style={{ maxWidth: '20rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <a
                                    href={selectedGuide}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: '#9D40B1', textDecoration: 'none' }}
                                >
                                    {selectedGuide}
                                </a>
                            </div>
                            <ClearIcon
                                onClick={clearSelectedGuide}
                                style={{ marginLeft: '3rem', cursor: 'pointer', color: '#666' }}
                            />
                        </div>
                    ) : (
                        <div style={fileInfoStyle}>
                            <InsertDriveFileIcon style={fileIconStyle} />
                            <div style={{ maxWidth: '20rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {selectedGuide.name}
                                <div style={{ color: '#666', fontSize: '0.75rem' }}>
                                    {(selectedGuide.size / 1024 / 1024).toFixed(2)} MB
                                </div>
                            </div>
                            <ClearIcon
                                onClick={clearSelectedGuide}
                                style={{ marginLeft: '3rem', cursor: 'pointer', color: '#666' }}
                            />
                        </div>
                    )
                ) : (
                    <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                        <input
                            type="file"
                            accept=".pdf,.doc,.docx,.txt,.md"
                            style={{ display: 'none' }}
                            onChange={handleFileSelect}
                        />
                        <UploadFileIcon style={iconStyle} />
                        <div style={textStyles}>Click to upload</div>
                        <div style={{ ...textStyles, color: '#888', marginTop: '0' }}>or drag and drop</div>
                        <div style={{ ...textStyles, color: '#888', fontSize: '0.625rem', marginTop: '0.5rem' }}>
                            Supported: PDF, DOC, DOCX, TXT, MD
                        </div>
                    </label>
                )}
            </CardContent>
        </Card>
    );
};

export default ImplementationGuideUpload;

import styled from "@emotion/styled";
import { SidebarDiv, SidebarText, FlexDiv } from "../../assets/styles/styles";
import cornucopia_icon from '../../assets/images/logo.jpeg';
import dashboard_img from '../../assets/images/dashboard_img.svg';
import inbox_img_one from '../../assets/images/inbox_img_one.svg';
import transactions_img_one from '../../assets/images/transactions_img_one.svg';
import products_img_one from '../../assets/images/products_img_one.svg';
import setting_img_one from '../../assets/images/settings_img_one.svg';
import logout_img from '../../assets/images/logout.svg';
import dashboard_img_two from '../../assets/images/dashboard_img_gray.svg';
import inbox_img_two from '../../assets/images/inbox_img_two.svg';
import transactions_img_two from '../../assets/images/transactions_img_two.svg';
import products_img_two from '../../assets/images/products_img_two.svg';
import setting_img_two from '../../assets/images/settings_img_two.svg';
import logout_img_two from '../../assets/images/logout_two.svg';
import integration_img_one from '../../assets/images/integration_instructions.svg';
import integration_img_two from '../../assets/images/integration-gray.svg';
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import MenuIcon from '@mui/icons-material/Menu';
import CrossIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from "react-router-dom";
import { useNotification } from "../../Context/NotificationContext";
import Badge from '@mui/material/Badge';
import LogoutModal from "../LogoutModal";
import { logout } from "../../Services/Authentication";

export const SidebarBtn = styled.button`
  position: relative;
  width: 11.4375rem;
  height: 2.6875rem;
  border-radius: 1.40625rem;
  border: transparent;
  background: transparent;
  transition: all 0.5s;
  &:hover {
    background: #3F0065;
    color: #fff;
    // Add this part to change the image on hover
    img {
      src: ${props => props.hoverImage || props.image}; // Assuming you have a hoverImage property in your SidebarBtnData
    }
  }
  &:active {
    background: #3F0065;
  }
`;

const NavigationIndex = {
    '/seller': 0,
    '/seller/inbox': 1,
    '/seller/products': 2,
    '/seller/transactions': 3,
    '/seller/products/add': 2,
    '/seller/products/details': 2,
    '/seller/settings': 4
}

const getBaseSidebarData = () => [
    {
        id: 1,
        margins: '-1rem 0rem 0rem 0.94rem',
        name: 'Dashboard',
        image_one: dashboard_img,
        image_two: dashboard_img_two,
        top_margin: '0.5rem',
        position: '',
        navigation_url: '/seller'
    },
    {
        id: 2,
        margins: '2rem 0rem 0rem 0.94rem',
        name: 'Inbox',
        image_one: inbox_img_two,
        image_two: inbox_img_one,
        top_margin: '0.5rem',
        position: '',
        navigation_url: '/seller/inbox'
    },
    {
        id: 3,
        margins: '2rem 0rem 0rem 0.94rem',
        name: 'Products',
        image_one: products_img_two,
        image_two: products_img_one,
        top_margin: '0.5rem',
        position: '',
        navigation_url: '/seller/products'
    },
    {
        id: 4,
        margins: '2rem 0rem 0rem 0.94rem',
        name: 'Transactions',
        image_one: transactions_img_two,
        image_two: transactions_img_one,
        top_margin: '0.5rem',
        position: '',
        navigation_url: '/seller/transactions'
    },
    {
        id: 5,
        margins: '2rem 0rem 0rem 0.94rem',
        name: 'Integration',
        image_one: integration_img_one,
        image_two: integration_img_two,
        top_margin: '0.5rem',
        position: '',
        navigation_url: '/seller/integration'
    },
    {
        id: 7,
        margins: '0rem 0rem 6rem 0.94rem',
        name: 'Settings',
        image_one: setting_img_two,
        image_two: setting_img_one,
        top_margin: '0.5rem',
        position: 'absolute',
        navigation_url: '/seller/settings'
    },
    {
        id: 8,
        margins: '0rem 0rem 2rem 0.94rem',
        name: 'Logout',
        image_one: logout_img_two,
        image_two: logout_img,
        top_margin: '0.5rem',
        position: 'absolute',
        navigation_url: '/signup-seller',
        onClick: true
    }

]

const SellerSidebar = ({ setIsOpen }) => {
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const { notification, notificationUpdated, setNotification, setNotificationUpdated } = useNotification();
    const isLaptop = useMediaQuery({ minWidth: 720 });
    const [openSidebar, setOpenSidebar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const [sidebarData, setSidebarData] = useState(getBaseSidebarData());
    const [hoveredStates, setHoveredStates] = useState(Array(getBaseSidebarData().length).fill(false));

    const openModal = () => {
        setIsOpen(prevIsOpen => !prevIsOpen);
    };

    // const [hoveredStates, setHoveredStates] = useState(Array(SidebarBtnData.length).fill(false));
    const handleMouseEnter = (index) => {
        setHoveredStates((prevHoveredStates) => {
            const updatedHoveredStates = [...prevHoveredStates];
            updatedHoveredStates[index] = true;
            return updatedHoveredStates;
        });
    };

    const handleMouseLeave = (index) => {
        setHoveredStates((prevHoveredStates) => {
            const updatedHoveredStates = [...prevHoveredStates];
            updatedHoveredStates[index] = false;
            return updatedHoveredStates;
        });
    };

    useEffect(() => {
        const needsStripeIntegration = !userData?.response?.stripe_connect_account ||
            Object.keys(userData?.response?.stripe_connect_account).length === 0;

        if (needsStripeIntegration) {
            const baseData = getBaseSidebarData();
            const onboardTab = {
                id: 6,
                margins: '2rem 0rem 0rem 0.94rem',
                name: 'Onboard',
                image_one: integration_img_one,
                image_two: integration_img_two,
                top_margin: '0.5rem',
                position: '',
                navigation_url: '/onboard'
            };

            // Find the index of Settings
            const settingsIndex = baseData.findIndex(item => item.name === 'Settings');

            // Only update if the Onboard tab is not already added
            if (!baseData.some(item => item.id === 6)) {
                baseData.splice(settingsIndex, 0, onboardTab);

                // Check if the sidebar data has actually changed
                if (JSON.stringify(baseData) !== JSON.stringify(sidebarData)) {
                    setSidebarData(baseData);
                    setHoveredStates(new Array(baseData.length).fill(false));
                }
            }
        } else {
            // No need to update if the integration is not needed
            const baseData = getBaseSidebarData();
            if (JSON.stringify(baseData) !== JSON.stringify(sidebarData)) {
                setSidebarData(baseData);
                setHoveredStates(new Array(baseData.length).fill(false));
            }
        }
    }, [userData?.response?.stripe_connect_account, sidebarData]); // Also depend on sidebarData for comparison


    useEffect(() => {
        const unreadMessages = notification.filter(
            (item) => item.notificationType === 'message' && !item.read
        );
        setUnreadMessageCount(unreadMessages.length);
    }, [notification])

    const handleCornucopiaIconClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        console.log("Onclose clicked")
        setLoading(false);
        setIsModalOpen(false);
    };

    const handlelogout = async (token) => {
        try {
            setLoading(true)
            const token = localStorage.getItem('refresh-token');
            await logout(token);
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('access-token-expiry');
            localStorage.removeItem('refresh-token-expiry');
            localStorage.removeItem('Cornucopia-user');
            setLoading(false);
            navigate('/');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <>
            {isModalOpen && <LogoutModal isOpen={isModalOpen} onClose={closeModal} onVerifyEmail={handlelogout} isVerifyingEmail={loading} heading='Logging Out' buttonText='Logout' subHeading="By navigating to the homepage, you will be logged out of your seller account?" showHeading={false} height='15.5rem' />}

            {isLaptop ?
                <SidebarDiv>
                    <img onClick={handleCornucopiaIconClick} src={cornucopia_icon} alt="" style={{ width: '12.125rem', height: '9.75rem', margin: '-2rem 0rem 0rem 0.5rem', cursor: 'pointer' }} />
                    {sidebarData.map((data, index) => (
                        <SidebarBtn
                            key={data.id}
                            style={{
                                background: (location.pathname === data.navigation_url) ? '#3F0065' : '',
                                margin: `${data.margins}`,
                                cursor: 'pointer',
                                position: `${data.position}`,
                                bottom: '0',
                                left: '0',
                            }}
                            onClick={() => {
                                if (data.onClick) {
                                    openModal();
                                } else {
                                    navigate(data.navigation_url);
                                }
                            }}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                        >
                            <FlexDiv style={{ gap: '1rem' }}>
                                <img
                                    src={(location.pathname === data.navigation_url || hoveredStates[index]) ? data.image_one : data.image_two}
                                    alt=""
                                    style={{ margin: '0.6rem 0rem 0.75rem 1.44rem' }}
                                />
                                <SidebarText
                                    style={{
                                        color: (location.pathname === data.navigation_url || hoveredStates[index]) ? '#fff' : '#676767',
                                        marginTop: `${data.top_margin}`,
                                    }}
                                >
                                    {data.name}
                                </SidebarText>
                                <Badge badgeContent={data.id === 2 ? unreadMessageCount : 0} color="error" style={{ marginTop: '1.2rem', marginLeft: '0.5rem' }}></Badge>
                            </FlexDiv>
                        </SidebarBtn>
                    ))}
                </SidebarDiv>
                :
                <>
                    <button style={{ display: 'contents' }} onClick={() => { setOpenSidebar(openSidebar ? false : true) }}>
                        <MenuIcon style={{ color: '#9803fa', position: 'fixed', margin: '1.5rem 0rem 0rem 1.5rem', zIndex: 11 }} />
                    </button>
                    <SidebarDiv style={{ zIndex: '12', visibility: openSidebar ? 'visible' : 'hidden', opacity: openSidebar ? '1' : '0', transition: 'opacity 0.5s, visibility 0.5s' }}>
                        <FlexDiv>
                            <img onClick={handleCornucopiaIconClick} src={cornucopia_icon} alt="" style={{ width: '9.5rem', height: '9.75rem', margin: '-2.2rem 0rem 0rem 1rem', cursor: 'pointer' }} />
                            <button style={{ display: 'contents' }} onClick={() => { setOpenSidebar(openSidebar ? false : true) }}>
                                <CrossIcon style={{ color: '#9803fa', margin: '1.7rem 0rem 0rem 1rem' }} />
                            </button>
                        </FlexDiv>
                        {sidebarData.map((data, index) => (
                            <SidebarBtn
                                key={data.id}
                                style={{
                                    background: (location.pathname === data.navigation_url) ? '#3F0065' : '',
                                    margin: `${data.margins}`,
                                    cursor: 'pointer',
                                    position: `${data.position}`,
                                    bottom: '0',
                                    left: '0',
                                }}
                                onClick={() => {
                                    if (data.onClick) {
                                        openModal(); // Call the onClick function when the option is clicked
                                    } else {
                                        navigate(data.navigation_url);
                                    }
                                    setOpenSidebar(openSidebar ? false : true);
                                }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={() => handleMouseLeave(index)}
                            >
                                <FlexDiv style={{ gap: '1rem' }}>
                                    <img src={(location.pathname === data.navigation_url || hoveredStates[index]) ? data.image_one : data.image_two} alt="" style={{ margin: '0.6rem 0rem 0.75rem 1.44rem' }} />
                                    <SidebarText
                                        style={{
                                            color: (location.pathname === data.navigation_url || hoveredStates[index]) ? '#fff' : '#676767',
                                            marginTop: `${data.top_margin}`,
                                        }}
                                    >
                                        {data.name}
                                    </SidebarText>
                                    <Badge badgeContent={data.id === 2 ? unreadMessageCount : 0} color="error" style={{ marginTop: '1.2rem', marginLeft: '0.5rem' }}></Badge>
                                </FlexDiv>
                            </SidebarBtn>
                        ))}
                    </SidebarDiv>
                </>
            }
        </>
    )
}
export default SellerSidebar;
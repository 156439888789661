import styled from '@emotion/styled'
import { loadStripe } from '@stripe/stripe-js'

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

export const BackDiv = styled.div`
  width: 3.0625rem;
  height: 3.0625rem;
  border-radius: 0.375rem;
  border: 1px solid #d4d4d4;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LivePreview = styled.div`
  display: flex;
  width: 12.0625rem;
  height: 2.8125rem;
  border-radius: 0.375rem;
  background: #3f0065;
  transition: background-color 0.3s ease;
  color: #fff;
  font-family: Poppins;
  font-size: 0.8125rem;
  font-weight: 600;
  text-align: center;
  &:hover {
    border: 1px solid #3f0065;
    color: #3f0065;
    background: #fff;
  }
`

export const FeaturesDiv = styled.div`
  display: flex;
  border-radius: 0.1875rem;
  background: #f9f9f9;
`

export const Images = styled.img`
  height: 9.4375rem;
  width: 12.4375rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
`

export const HEADER_DATA = [
  {
    id: '1',
    name: 'Home',
    margin_right: '2rem',
    navigation_url: '/'
  },
  {
    id: '2',
    name: 'Marketplace',
    margin_right: '2rem',
    navigation_url: '/products'
  },
  {
    id: '3',
    name: 'Custom AI',
    margin_right: '2rem',
    navigation_url: '/custom-ai'
  },
  // {
  //   id: '4',
  //   name: 'Sellers',
  //   margin_right: '2rem',
  //   navigation_url: '/sellers'
  // },
  {
    id: '4',
    name: 'Login',
    margin_right: '0rem',
    navigation_url: '/login'
  }
]

export const BackToProduct = {
  color: '#888',
  fontFamily: 'Poppins',
  fontSize: '0.8125rem',
  fontWeight: 500,
  marginTop: '0.1rem'
}

export const AddProduct = {
  color: '#6D0085',
  fontFamily: 'Poppins',
  fontSize: '1.25rem',
  fontWeight: 'bold'
}

export const Heading = {
  color: '#3F0065',
  fontFamily: 'Poppins',
  fontSize: '1.5625rem',
  fontWeight: 500
}

export const HeadingNum = {
  color: '#8E8E8E',
  fontFamily: 'Poppins',
  fontSize: '1.25rem',
  fontStyle: 'italic',
  fontWeight: 500
}

export const FeaturesDivText = {
  color: '#8F8F8F',
  fontFamily: 'Poppins',
  fontSize: '0.75rem',
  fontWeight: 500
}

export const tickStyle = {
  width: '0.95988rem',
  height: '0.72044rem',
  fill: '#3F0065'
}

export const summary = {
  color: '#888',
  fontFamily: 'Poppins',
  fontSize: '0.9375rem',
  fontWeight: 500
}

export const TickText = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
  'boris nisi ut aliquip ex ea commodo consequat.',
  'uis aute irure dolor in reprehenderit in voluptate velit esse eu',
  'Lorem Ipsum is simply',
  'Excepteur sint occaecat cupidatat non',
  'Eget nulla facilisi etiam dignissim diam',
  'A pellentesque sit amet porttitor'
]

import styled from '@emotion/styled'

export const Heading = {
  color: '#6D0085',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '3rem',
  fontStyle: 'normal',
  fontWeight: 600
}

export const ActiveText = {
  color: '#9D40B1',
  fontFamily: 'Poppins',
  fontSize: '1.25rem',
  fontStyle: 'normal',
  fontWeight: 600
}

export const FilterContainer = styled.div`
  display: flex;
  border-radius: 0.375rem;
  background: #f9f9f9;
  justify-content: space-between;
  height: 2.8125rem;
`

export const FromTo = styled.div`
  color: #767676;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const DateCheckerContainer = styled.div`
  width: 7.5625rem;
  height: 2.125rem;
  flex-shrink: 0;
  border-radius: 1.0625rem;
  border: 1px solid rgba(66, 0, 81, 0.62);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 0.75rem;
  color: #6d0085;
  position: relative;
  cursor: pointer;
`

export const TagDiv = styled.div`
  display: flex;
  height: 2.125rem;
  border-radius: 0.3125rem;
  background: #f9f9f9;
  color: #8f8f8f;
  font-family: Poppins;
  font-size: 0.8125rem;
  font-weight: 500;
`

export const HEADER_DATA = [
  {
    id: '1',
    name: 'Home',
    margin_right: '2rem',
    navigation_url: '/'
  },
  // {
  //   id: '2',
  //   name: 'My Products',
  //   margin_right: '2rem',
  //   navigation_url: '/my-products'
  // },
  {
    id: '2',
    name: 'Marketplace',
    margin_right: '2rem',
    navigation_url: '/products'
  },
  {
    id: '3',
    name: 'Custom AI',
    margin_right: '2rem',
    navigation_url: '/custom-ai'
  }
  // {
  //   id: '4',
  //   name: 'Sellers',
  //   margin_right: '0rem',
  //   navigation_url: '/login'
  // }
]

export const TagData = [
  'Business',
  'Analytics',
  'Learning',
  'Sales & Advertisement'
]

export const TableHeaders = [
  {
    name: '',
    align: 'left'
  },
  {
    name: 'Title',
    align: 'left'
  },
  {
    name: 'Purchased On',
    align: 'left'
  },
  {
    name: 'Category',
    align: 'left'
  },
  {
    name: 'Type',
    align: 'left'
  },
  {
    name: 'Price',
    align: 'left'
  },
  {
    name: 'Total Reviews',
    align: 'left'
  },
  {
    name: 'Actions',
    align: 'center'
  }
]

import { FlexDiv, SellerProfileDiv } from "../../assets/styles/styles";
import notificationBell from '../../assets/images/notification_bell.svg';
import { useMediaQuery } from 'react-responsive'
import styled from "@emotion/styled";
import Notifications from "../Notifications/Notifications";
import { useState, useEffect, useRef } from "react";
import { useNotification } from "../../Context/NotificationContext";
import io from 'socket.io-client';
import { IP_ADD } from "../../constants/api";
import { logout } from "../../Services/Authentication";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import AvatarLogo from "../AvatarLogo";
import { fetchNotificationsApi, deleteNotificationApi } from "../../Services/Notification";
import { Logout, Settings } from "@mui/icons-material";
export const NotificationDiv = styled.div`
  display: block;
  position: absolute;
  top: 4.5rem;
  transition: height 0.3s;
  height: ${props => props.open ? '28rem' : '0px'};
  overflow: hidden;
  width: ${props => props.open ? '' : '0px'};
  z-index: 1000;
  ${props => props.open ? '' : 'overflow: hidden'};
`;

const ProfileDropdown = styled.div`
  position: absolute;
  top: 4.5rem;
  right: ${props => props.isLaptop ? '8rem' : '6rem'};
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  width: 12rem;
  z-index: 1000;
  display: ${props => props.open ? 'block' : 'none'};
`;

const DropdownItem = styled.button`
  width: 100%;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: none;
  background: none;
  cursor: pointer;
  color: #333;
  font-family: 'Poppins';
  font-size: 0.875rem;
  
  &:hover {
    background: #F9F9F9;
  }
  
  &:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  
  &:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`;

const ENDPOINT = `${IP_ADD}`;

const UserProfileSeller = () => {
    const navigate = useNavigate();
    const isLaptop = useMediaQuery({ minWidth: 620 });
    const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
    const profileDropdownRef = useRef(null);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [open, setOpen] = useState(false);
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const userId = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const notificationRef = useRef(null);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const { notification, notificationUpdated, setNotification, setNotificationUpdated, setPaymentFailSeller } = useNotification();
    const {
        chatId,
        setChatId,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        chatAvailable,
        setChatAvailable,
        chatsCopy,
        setChatsCopy,
        fetchAgain,
        setFetchAgain,
        chatListClicked,
        setChatListClicked,
        setMessages,
        socket,
        setSocket,
    } = useNotification();
    const chatsCopyRef = useRef(chatsCopy);
    const chatIdRef = useRef(chatId);
    useEffect(() => {
        chatsCopyRef.current = chatsCopy;
    }, [chatsCopy]);

    useEffect(() => {
        chatIdRef.current = chatId;
    }, [chatId]);

    const handleClickOutside = (event) => {
        if (notificationRef.current && !notificationRef.current.contains(event.target)) {
            // Click outside the notifications area
            setNotificationOpen(false);
        }
    };
    const handleNotificationClick = (event) => {
        event.stopPropagation();
    };

    const handleLogout = async () => {
        const token = localStorage.getItem('refresh-token');
        logout(token);
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('access-token-expiry');
        localStorage.removeItem('refresh-token-expiry');
        localStorage.removeItem('Cornucopia-user');
        navigate('/login');
    }

    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Handle profile dropdown
            if (!profileDropdownRef.current?.contains(event.target)) {
                setProfileDropdownOpen(false);
            }
            // Handle notifications
            if (!notificationRef.current?.contains(event.target)) {
                setNotificationOpen(false);
            }
        };

        document.addEventListener("click", handleGlobalClick);
        return () => {
            document.removeEventListener("click", handleGlobalClick);
        };
    }, []);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };
    const handlePaymentError = async (newMessageReceived) => {

        if (newMessageReceived.type === "payment" && newMessageReceived.message === 'Payment intent failed!') {
            setPaymentFailSeller(true);
        }
    };
    const handleUserSuspended = async (newMessageReceived) => {

        if (userData.response.id === newMessageReceived) {
            setSnackbarMsg("Your account has been suspended");
            setSnackbarSeverity('error');
            setOpen(true);
            setTimeout(() => {
                handleLogout();
            }, 2500);
        }
    };
    useEffect(() => {

        const handleNotification = async (newMessageReceived) => {
            const { chat } = newMessageReceived.data;

            if (chat && newMessageReceived.type === "message") {
                let idMatched = false;

                chatsCopyRef.current.forEach(chat => {
                    if (chat._id === newMessageReceived.data.chat._id) {
                        idMatched = true;
                    }

                    if (!idMatched) {
                        setFetchAgain(true);
                    }
                });

                if (chatsCopyRef.current.length === 0) {
                    setFetchAgain(true);
                }

                const notificationApiResult = await fetchNotificationsApi(userId.response.id);

                if (notificationApiResult.success && (!chatIdRef.current || chatIdRef.current !== chat._id)) {
                    setNotification([...notificationApiResult.data]);
                    setFetchAgain(true);
                } else {
                    setMessages((prevMessages) => [...prevMessages, newMessageReceived.data]);
                    const latestNotification = notificationApiResult.data[notificationApiResult.data.length - 1];
                    let notificationId;

                    if (latestNotification) {
                        notificationId = latestNotification._id;
                    }

                    const deleteNotificationResult = await deleteNotificationApi(notificationId);

                    if (!deleteNotificationResult.success) {
                        console.log("Error deleting notification", deleteNotificationResult.error);
                    }
                }
            } else if (newMessageReceived.type === "payment") {

                const notificationApiResult = await fetchNotificationsApi(userId.response.id);

                setNotification([...notificationApiResult.data]);
                setFetchAgain(true);
            }
        };
        const user = localStorage.getItem("Cornucopia-user");
        if (user) {
            const newSocket = io(ENDPOINT);
            newSocket.emit("setup", userId.response);
            newSocket.on("notification", handleNotification);
            newSocket.on("error", handlePaymentError);
            newSocket.on("userSuspended", handleUserSuspended);

            setSocket(newSocket);

        }

        return () => {
            // Cleanup socket when component unmounts
            if (socket && socket.connected) {
                socket.disconnect();
            }
        };
    }, []);
    useEffect(() => {

        return () => {
            // Cleanup socket when component unmounts
            if (socket) {
                socket.disconnect();
            }
        };

    }, [socket]); // Add socket as a dependency
    useEffect(() => {
        setNotificationUpdated(true);
    }, [notification]);

    const fetchData = async () => {
        const notificationApiResult = await fetchNotificationsApi(userId.response.id);

        if (notificationApiResult.success) {
            setNotification([...notificationApiResult.data]);
        } else {
            console.log("Error in notification fetching", notificationApiResult.error);
        }
    };

    useEffect(() => {
        if (userId)
            fetchData();

    }, []);
    const unreadNotificationsCount = notification.filter(item => !item.read).length;
    const handleSettingsClick = () => {
        navigate('/seller/settings'); 
        setProfileDropdownOpen(false);
    };
    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>
            <FlexDiv style={{ marginRight: unreadNotificationsCount > 0 ? '' : '2rem' }}>
                <div ref={profileDropdownRef}>
                <button style={{ display: 'contents' }} onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}>
                    <SellerProfileDiv style={{ width: '11.4rem', height: '2.8rem' }}>
                        <FlexDiv style={{ justifyContent: 'space-around' }}>
                            {userData?.response?.displayPicture ?
                                <img src={userData.response.displayPicture} alt="" style={{ width: '1.9rem', height: '1.9rem', margin: '0.4rem 0rem 0rem 0.6rem', borderRadius: '1.9rem' }} /> :
                                <div style={{ margin: '0.4rem 0rem 0rem 0.6rem' }}>
                                    <AvatarLogo width={"1.9rem"} height={"1.9rem"} mobile={true} />
                                </div>
                            }
                            <div>
                                <h1 style={{ color: '#000', fontFamily: 'Poppins', fontSize: '0.75rem', fontWeight: '500', margin: '0.5rem 1rem 0rem 0rem' }}>{userData ? userData.response.firstName : 'Felix Jhonson'}</h1>
                                <div style={{ color: '#8F8F8F', fontFamily: 'Poppins', fontSize: '0.5625rem', fontWeight: '500', marginRight: '1rem', maxWidth: '7rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userData ? userData.response.email : 'felix@gmail.com'}</div>
                            </div>
                        </FlexDiv>
                    </SellerProfileDiv>
                </button>
                <ProfileDropdown open={profileDropdownOpen} isLaptop={isLaptop}>
                        <DropdownItem onClick={handleSettingsClick}>
                            <Settings size={16} />
                            Settings
                        </DropdownItem>
                        <DropdownItem onClick={handleLogout}>
                            <Logout size={16} />
                            Logout
                        </DropdownItem>
                    </ProfileDropdown>
                </div>
                <div style={{ width: '1.43rem', height: '1px', background: '#A5A5A5', transform: 'rotate(-90deg)', marginTop: '1.4rem' }}></div>
                <button style={{ display: 'contents' }} onClick={() => { setNotificationOpen(!notificationOpen) }} ref={notificationRef}>
                    <FlexDiv style={{ height: '2.8125rem', width: '2.8125rem', borderRadius: '1.40625rem', backgroundColor: '#F9F9F9' }}>
                        <img src={notificationBell} alt="" style={{ width: '1rem', height: '1.0625rem', margin: '0.87rem 0rem 0rem 0.94rem' }} />
                    </FlexDiv>
                    {unreadNotificationsCount > 0 && (
                        <div style={{ width: '1.3125rem', height: '1.3125rem', backgroundColor: '#DB0000', borderRadius: '1.40625rem', marginLeft: '-1.4rem', marginRight: isLaptop ? '3rem' : '1rem', textAlign: 'center', color: 'white', fontSize: '0.625rem', paddingTop: '0.2rem' }}>{unreadNotificationsCount}</div>
                    )}
                </button>
                <NotificationDiv
                    open={notificationOpen}
                    style={{ right: isLaptop ? '3rem' : '1rem' }}
                    onClick={handleNotificationClick}
                >
                    <Notifications />
                </NotificationDiv>
            </FlexDiv>
        </>
    )
}
export default UserProfileSeller;

import React, { useState, useEffect } from 'react';
import NavBar from "../../components/Navbar";
import { HEADER_DATA } from "./constants";

import {
    Container,
    Grid,
    Paper,
    Typography,
    Card,
    CardContent,
    Tabs,
    Tab,
    Button,
    TextField,
    IconButton,
    Avatar,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Badge,
    Menu,
    MenuItem
} from '@mui/material';
import {
    ThumbUp,
    ThumbDown,
    Comment,
    Bookmark,
    Share,
    NotificationsNone,
    FilterList,
    Create,
    Edit,
    Delete
} from '@mui/icons-material';

const ResourcesPage = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [resources, setResources] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState({
        type: 'all',
        industry: 'all',
        sortBy: 'newest'
    });
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);

    // Styles
    const styles = {
        container: {
            maxWidth: '1800px',
            py: 4,
            px: { xs: 2, md: 4 },
        },
        header: {
            color: '#6A007C',
            fontWeight: 'bold',
            mb: 4,
            textAlign: 'center'
        },
        card: {
            border: '1px solid #9600AF',
            borderRadius: '0.69rem',
            mb: 2,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.2s',
            '&:hover': {
                transform: 'scale(1.03)',
            }
        },
        button: {
            backgroundColor: '#6A007C',
            color: '#FFFFFF',
            borderRadius: '2.125rem',
            px: 3,
            py: 1,
            '&:hover': {
                backgroundColor: '#3F0065',
            }
        },
        searchBar: {
            mb: 3,
            '& .MuiOutlinedInput-root': {
                borderRadius: '2.125rem',
                '& fieldset': {
                    borderColor: '#9600AF',
                }
            }
        },
        tab: {
            fontWeight: 'bold',
            textTransform: 'none',
        },
        iconButton: {
            color: '#6A007C',
        },
        industryButton: {
            backgroundColor: '#6A007C',
            color: '#FFFFFF',
            borderRadius: '2.125rem',
            px: 3,
            py: 1,
            '&:hover': {
                backgroundColor: '#3F0065',
            },
            margin: '0.5rem'
        }
    };

    // Resource Card Component
    const ResourceCard = ({ resource }) => (
        <Card sx={styles.card}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
                        <div>
                            <Typography variant="h6" color="#6A007C">
                                {resource.title}
                            </Typography>
                            <Typography variant="subtitle2" color="text.secondary">
                                {resource.type} • {resource.industry}
                            </Typography>
                        </div>
                        <Bookmark sx={styles.iconButton} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1" color="text.secondary">
                            {resource.content}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {resource.tags.map((tag, index) => (
                            <Chip
                                key={index}
                                label={tag}
                                size="small"
                                sx={{ mr: 1, mb: 1, backgroundColor: '#E6F0F5', color: '#6A007C' }}
                            />
                        ))}
                    </Grid>

                    <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar src={resource.author.avatar} sx={{ width: 32, height: 32, mr: 1 }} />
                            <Typography variant="subtitle2" color="text.secondary">
                                {resource.author.name}
                            </Typography>
                        </div>
                        <div>
                            <IconButton sx={styles.iconButton}>
                                <Badge badgeContent={resource.likes} color="primary">
                                    <ThumbUp />
                                </Badge>
                            </IconButton>
                            <IconButton sx={styles.iconButton}>
                                <Badge badgeContent={resource.comments} color="primary">
                                    <Comment />
                                </Badge>
                            </IconButton>
                            <IconButton sx={styles.iconButton}>
                                <Share />
                            </IconButton>
                            <IconButton sx={styles.iconButton}>
                                <Edit />
                            </IconButton>
                            <IconButton sx={styles.iconButton}>
                                <Delete />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );

    // Handle Industry Filter
    const handleIndustryFilter = (industry) => {
        setFilters({ ...filters, industry });
    };

    // Submit Content Dialog
    const SubmitDialog = () => (
        <Dialog
            open={showSubmitDialog}
            onClose={() => setShowSubmitDialog(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Share Your Knowledge</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Title"
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    label="Content"
                    multiline
                    rows={4}
                    margin="normal"
                    variant="outlined"
                />
                {/* Add more fields for type, industry, tags, etc. */}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowSubmitDialog(false)}>Cancel</Button>
                <Button variant="contained" sx={styles.button}>Submit</Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <NavBar header_data={HEADER_DATA} header_button={{ label: 'Join the venture', navigation_url: '/buyerseller' }} mobile_nav_height={'16.5rem'} />
            <Container maxWidth={false} sx={styles.container}>
                {/* Header Section */}
                <Grid container spacing={3} mb={4}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h2" sx={styles.header}>
                            Resource Center
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" justifyContent="flex-end">
                        <IconButton sx={styles.iconButton}>
                            <Badge badgeContent={3} color="primary">
                                <NotificationsNone />
                            </Badge>
                        </IconButton>
                    </Grid>
                </Grid>

                {/* Industry Filter Section */}
                <Grid container spacing={2} mb={4}>
                    <Grid item xs={12}>
                        <Button sx={styles.industryButton} onClick={() => handleIndustryFilter('real-estate')}>Resources for Real Estate SMBs</Button>
                        <Button sx={styles.industryButton} onClick={() => handleIndustryFilter('small-business')}>Resources for Small Businesses</Button>
                        <Button sx={styles.industryButton} onClick={() => handleIndustryFilter('tech')}>Resources for Tech Startups</Button>
                        <Button sx={styles.industryButton} onClick={() => handleIndustryFilter('all')}>All Resources</Button>
                    </Grid>
                </Grid>

                {/* Search and Filter Section */}
                <Grid container spacing={2} mb={4}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            placeholder="Search resources..."
                            variant="outlined"
                            sx={styles.searchBar}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" gap={2}>
                        <Button
                            startIcon={<FilterList />}
                            onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                            sx={styles.button}
                        >
                            Filter
                        </Button>
                        <Button
                            startIcon={<Create />}
                            onClick={() => setShowSubmitDialog(true)}
                            sx={styles.button}
                        >
                            Create Post
                        </Button>
                    </Grid>
                </Grid>

                {/* Main Content Section */}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Tabs
                            value={activeTab}
                            onChange={(e, v) => setActiveTab(v)}
                            sx={{ mb: 3 }}
                            TabIndicatorProps={{ sx: { backgroundColor: '#6A007C' } }}
                        >
                            <Tab label="Featured" sx={styles.tab} />
                            <Tab label="Latest" sx={styles.tab} />
                            <Tab label="Popular" sx={styles.tab} />
                            <Tab label="Following" sx={styles.tab} />
                        </Tabs>

                        {/* Resource Cards */}
                        <ResourceCard
                            resource={{
                                title: "Getting Started with AI for Small Business",
                                type: "Guide",
                                industry: filters.industry === 'all' ? "General" : filters.industry,
                                content: "Learn how to implement AI solutions in your small business...",
                                tags: ["AI", "Small Business", "Getting Started"],
                                author: {
                                    name: "John Doe",
                                    avatar: "/api/placeholder/32/32"
                                },
                                likes: 42,
                                comments: 15
                            }}
                        />
                    </Grid>

                    {/* Sidebar */}
                    <Grid item xs={12} md={4}>
                        <Paper sx={{ p: 3, ...styles.card }}>
                            <Typography variant="h6" color="#6A007C" gutterBottom>
                                Upcoming Events
                            </Typography>
                            {/* Add event listings */}
                        </Paper>

                        <Paper sx={{ p: 3, ...styles.card }}>
                            <Typography variant="h6" color="#6A007C" gutterBottom>
                                Top Contributors
                            </Typography>
                            {/* Add contributor listings */}
                        </Paper>
                    </Grid>
                </Grid>

                {/* Dialogs */}
                <SubmitDialog />

                {/* Filter Menu */}
                <Menu
                    anchorEl={filterAnchorEl}
                    open={Boolean(filterAnchorEl)}
                    onClose={() => setFilterAnchorEl(null)}
                >
                    <MenuItem>All Content</MenuItem>
                    <MenuItem>Blogs</MenuItem>
                    <MenuItem>Guides</MenuItem>
                    <MenuItem>Case Studies</MenuItem>
                    <MenuItem>Q&A</MenuItem>
                </Menu>
            </Container>
        </>
    );
};

export default ResourcesPage;

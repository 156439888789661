import React from "react";
import Modal from "react-modal";

const OrderSummaryModal = ({
  price,
  showOrderSummary,
  handleOrderSummaryCancel,
  isSmallMobile,
  productName,
  handleOrderSummaryConfirm,
}) => {
  return (
    <Modal
      isOpen={showOrderSummary}
      onRequestClose={handleOrderSummaryCancel}
      contentLabel="Order Summary"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        },
        content: {
          width: isSmallMobile ? "95%" : "500px",
          maxHeight: "320px", // Set maxHeight to prevent overflow on large content
          height: "auto", // Ensures it adjusts to fit content
          margin: "auto",
          borderRadius: "0.75rem",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          padding: "1.5rem",
          zIndex: 1001,
          inset: isSmallMobile ? "0px" : "40px",
          top: isSmallMobile ? "25%" : "",
          left: isSmallMobile ? "2.5%" : "",
          overflowY: "auto", // Enable scrolling if content exceeds max height
        },
      }}
    >
      <h2
        style={{
          color: "#46137C",
          fontFamily: "Poppins",
          fontSize: "1.5rem",
          fontWeight: 600,
          marginBottom: "1.5rem",
        }}
      >
        Order Summary
      </h2>
      <div
        style={{
          padding: "1rem",
          backgroundColor: "#F9F5FF",
          borderRadius: "0.5rem",
          marginBottom: "1.5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.75rem",
          }}
        >
          <span style={{ color: "#666" }}>Product</span>
          <span style={{ fontWeight: 500 }}>{productName}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.75rem",
          }}
        >
          <span style={{ color: "#666" }}>Price</span>
          <span style={{ fontWeight: 500 }}>${price}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "0.75rem",
            borderTop: "1px solid #E5E7EB",
          }}
        >
          <span style={{ fontWeight: 600 }}>Total</span>
          <span style={{ fontWeight: 600, color: "#46137C" }}>${price}</span>
        </div>
      </div>
      <div style={{ display: "flex", gap: "0.75rem" }}>
        <button
          onClick={handleOrderSummaryCancel}
          style={{
            flex: 1,
            padding: "0.75rem",
            borderRadius: "0.4375rem",
            background: "#EDEDED",
            color: "#8F8F8F",
            border: "none",
            fontFamily: "Poppins",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          Cancel
        </button>
        <button
          onClick={handleOrderSummaryConfirm}
          style={{
            flex: 1,
            padding: "0.75rem",
            borderRadius: "0.4375rem",
            background: "#3F0065",
            color: "#FFF",
            border: "none",
            fontFamily: "Poppins",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          Proceed to Payment
        </button>
      </div>
    </Modal>
  );
};

export default OrderSummaryModal;

import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
import Chart from 'react-apexcharts';
import YellowLine from '../../assets/images/yellow_line.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TOTAL_SALES } from '../../constants/text';
import { getAllReviews } from '../../Services/Products';

const StatsChart = ({ productId }) => {
    const isSmallScreen = useMediaQuery('(max-width:440px)');
    const isSmallerScreen = useMediaQuery('(max-width:380px)');
    const [chartData, setChartData] = useState({
        series: [0, 0, 0],
        options: {
            chart: {
                type: 'donut',
            },
            labels: ['Average', 'Below Average', 'Above Average'],
            colors: ['#D2BD70', '#870000', '#8EC37B'],
            legend: {
                position: 'right',
                offsetY: 0,
                itemMargin: {
                    vertical: 5,
                },
                formatter: function (seriesName, opts) {
                    return `${seriesName}`;
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    return `${Math.round(val)}% `;
                },
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                showAlways: true,
                                label: 'Reviews',
                                fontSize: '0.6875rem',
                                fontFamily: 'Poppins',
                                fontWeight: '400',
                            }
                        }
                    }
                }
            },
            responsive: [
                {
                    breakpoint: 500,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetY: 0,
                        },
                    },
                },
            ],
        }
    });

    const [totalReviews, setTotalReviews] = useState(0);
    const selectedStartDate = new Date(2024, 0, 1);
    const selectedEndDate = new Date(2025, 11, 31);

    const processReviewData = (reviews) => {
        if (!reviews || !Array.isArray(reviews) || reviews.length === 0) return;
        
        // Filter out the metadata object
        const actualReviews = reviews.filter(review => review.rating);
        
        let belowAverage = 0;
        let average = 0;
        let aboveAverage = 0;
        
        actualReviews.forEach(review => {
            if (review.rating < 3) belowAverage++;
            else if (review.rating === 3) average++;
            else aboveAverage++;
        });

        const total = actualReviews.length;
        setTotalReviews(total);

        // Calculate percentages
        const getPercentage = (value) => (total === 0 ? 0 : (value / total) * 100);

        setChartData(prevState => ({
            ...prevState,
            series: [
                getPercentage(average),
                getPercentage(belowAverage),
                getPercentage(aboveAverage)
            ]
        }));
    };

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const { reviews, totalPages, error } = await getAllReviews(productId, selectedStartDate, selectedEndDate);
                if (error) {
                    console.error(error);
                } else {
                    processReviewData(reviews);
                }
            } catch (error) {
                console.error('Error fetching review data:', error);
            }
        };

        if (productId) {
            fetchReviews();
        }
    }, [productId]);

    const totalReviewsStyle = {
        color: '#666',
        fontFamily: 'Poppins',
        fontSize: '0.9375rem',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
    };

    const totalReviewsNumber = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '1.375rem',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
    };

    return (
        <Card
            variant="outlined"
            style={{
                width: '100%',
                borderRadius: '0.5625rem',
                background: '#FFF',
            }}
            sx={{ '& .MuiCardContent-root': { padding: '1.56rem 0rem 0rem 1.12rem' } }}
        >
            <CardContent>
                <div style={{ display: 'flex' }}>
                    {!isSmallScreen && 
                        <img src={YellowLine} alt="" />
                    }
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{
                            ...totalReviewsStyle, 
                            marginLeft: isSmallScreen ? (isSmallerScreen ? '5rem' : '8.12rem') : '0.87rem', 
                            textAlign: isSmallScreen ? 'center' : 'left' 
                        }}>
                            {TOTAL_SALES}
                        </div>
                        {!isSmallScreen && 
                            <div style={{ ...totalReviewsNumber, marginLeft: '1.13rem' }}>
                                {totalReviews.toLocaleString()}
                            </div>
                        }
                    </div>
                </div>
                <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="donut"
                    width="380"
                    style={{ 
                        marginTop: isSmallScreen ? '0' : '-3rem', 
                        marginLeft: isSmallScreen ? (isSmallerScreen ? '-3rem' : '0') : '3rem',
                    }}
                />
            </CardContent>
        </Card>
    );
};

export default StatsChart;
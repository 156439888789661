import { FlexDiv } from "../../assets/styles/styles";
import { useMediaQuery } from "react-responsive";
import ImageSlider from "../../Pages/BuyerProductDetails/ImageSlider";
import styled from "@emotion/styled";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Typography, Grid } from "@mui/material";
import GroupIcon from '../../assets/images/group.svg';
import Trolley from "../../assets/images/trolley.svg";
import Coins from "../../assets/images/coins.svg";
import RevenueStats from "../ProductDetaisSeller/RevenueStats";
import StatsChart from "../ProductDetaisSeller/StatsChart";
import { useRef, useState, useEffect } from "react";
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReviewCardSeller from "../ProductDetaisSeller/ReviewCardSeller";
import PublishReview from "../ProductDetaisSeller/PublishReview";
import RespondToReview from "../ProductDetaisSeller/RespondToReview";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReviewCard from "../ProductDetailsBuyer/ReviewCard";
import { useNavigate, useParams } from "react-router-dom";
import DateChecker from "./DateChecker";
import VideoModal from "../ProductDetailsBuyer/VideoModal";
import UserProfileSeller from "./UserProfileSeller";
import { BACK_TO_PRODUCT_LIST, PRODUCT_DETAILS, REVIEWS_AND_RATING, DOWNLOAD, DELETE_REVIEW, LIVE_PREVIEW } from "../../constants/text";
import CircularProgress from '@mui/material/CircularProgress';
import NoReview from "./NoReviews";
import CustomPagination from "../CustomPagination";
import { getAllProducts, getReviews } from "../../Services/Products";
import { Helmet } from "react-helmet";
const BackDiv = styled.div`
    width: 3.0625rem;
    height: 3.0625rem;
    border-radius: 0.375rem;
    border: 1px solid #D4D4D4;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IDDiv = styled.div`
    width: 6.5625rem;
    height: 1.75rem;
    border-radius: 0.375rem;
    background: #CDCDCD;
    color: #696969;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-style: italic;
    font-weight: 500;
    text-align: center;
    padding-top: 0.3rem;
`;

const PriceBtn = styled.div`
    width: 5.3125rem;
    height: 2.8125rem;
    border-radius: 0.375rem;
    border: 1px solid #3F0065;
    color: #3F0065;
    font-family: Poppins;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding-top: 0.4rem;
    transition: background 0.3s ease;
    &:hover {
        color: #FFF;
        background: #3F0065;
    }
`;

const LivePreview = styled.div`
    display: flex;
    width: 10rem;
    height: 2.8125rem;
    border-radius: 0.375rem;
    background: #3F0065;
    transition: background-color 0.3s ease;
    color: #FFF;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-weight: 600;
    text-align: center;
    &:hover {
        border: 1px solid #3F0065; 
        color: #3F0065;
        background: #FFF;
    }        
`;

const FeaturesDiv = styled.div`
    display: flex;
    border-radius: 0.1875rem;
    background: #F9F9F9;
`;

const Images = styled.img`
    width: 10.625rem;
    height: 8.0625rem;
    border-radius: 0.5rem;
`;

const FilterContainer = styled.div`
  display: flex;
  border-radius: 0.375rem;
  background: #F9F9F9;
  justify-content: space-between;
  height: 2.8125rem;
`;

const BackToProduct = {
    color: "#888",
    fontFamily: "Poppins",
    fontSize: "0.8125rem",
    fontWeight: 500,
    marginTop: '0.1rem'
};

const AddProduct = {
    color: "#6D0085",
    fontFamily: "Poppins",
    fontSize: "1.25rem",
    fontWeight: 'bold'
};

const Heading = {
    color: "#3F0065",
    fontFamily: "Poppins",
    fontSize: "1.5625rem",
    fontWeight: 500
}

const HeadingNum = {
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontSize: "1.25rem",
    fontStyle: "italic",
    fontWeight: 500
}

const FeaturesDivText = {
    color: "#8F8F8F",
    fontFamily: "Poppins",
    fontSize: "0.75rem",
    fontWeight: 500
}

const summary = {
    color: "#888",
    fontFamily: "Poppins",
    fontSize: "0.9375rem",
    fontWeight: 500
}

const Download_Text = {
    color: "#ABABAB",
    fontFamily: "Poppins",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 500
}

const ReviewsData = [
    {
        id: 1,
        ratings: '4',
        stars: 4,
        smiley: true
    },
    {
        id: 2,
        ratings: '4.8',
        stars: 4,
        smiley: true
    },
    {
        id: 3,
        ratings: '1.4',
        stars: 2,
        smiley: false
    }
]

const categorizeReviews = (reviews) => {
    let averageCount = 0;
    let belowAverageCount = 0;
    let aboveAverageCount = 0;

    if (Array.isArray(reviews) && reviews.length > 1) {
        reviews.slice(0, -1).forEach((review) => {
            const rating = review.rating;

            if (rating === 2.5) {
                averageCount += 1;
            } else if (rating < 2.5) {
                belowAverageCount += 1;
            } else {
                aboveAverageCount += 1;
            }
        });
    }

    return {
        averageCount,
        belowAverageCount,
        aboveAverageCount,
    };
};
const SellerProductDetails = () => {
    const isLaptop = useMediaQuery({ minWidth: 620 });
    const isLargeLaptop = useMediaQuery({ minWidth: 720 });
    const isDesktop = useMediaQuery({ minWidth: 1050 });
    const isDesktopTwo = useMediaQuery({ minWidth: 1300 });
    const isDesktopThree = useMediaQuery({ minWidth: 1230 });
    const isDesktopFour = useMediaQuery({ minWidth: 1150 });
    const isTab = useMediaQuery({ minWidth: 1000 });
    const isTabTwo = useMediaQuery({ minWidth: 850 });
    const isTabThree = useMediaQuery({ maxWidth: 800, minWidth: 720 });
    const isTabFour = useMediaQuery({ minWidth: 720 });
    const isMobile = useMediaQuery({ minWidth: 555 });
    const isMobileTwo = useMediaQuery({ minWidth: 410 });
    const isSmallMobile = useMediaQuery({ maxWidth: 410 });
    const startDatePickerRef = useRef(null);
    const endDatePickerRef = useRef(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [totalBuyers, setTotalBuyers] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const navigate = useNavigate();
    const { productId } = useParams();
    const [productDetails, setProductDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reviews, setReviews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const BackToProducts = () => {
        navigate('/seller/products');
    }
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    const getProduct = async () => {
        try {
            const { productDetails, error: productError } = await getAllProducts(productId);
            if (productError) {
                BackToProducts();
            }

            const { reviews, totalPages, error: reviewsError } = await getReviews(productId, currentPage, selectedStartDate, selectedEndDate, setCurrentPage);

            if (reviewsError) {
                BackToProducts();
            }
            setTotalBuyers(productDetails?.buyers ? productDetails?.buyers?.length : 0);
            setTotalRevenue(productDetails?.buyers ? productDetails?.buyers?.length * productDetails?.price : 0)
            setProductDetails(productDetails);
            setReviews(reviews);
            setTotalPages(totalPages);
            const { averageCount, belowAverageCount, aboveAverageCount } = categorizeReviews(reviews);
        } catch (error) {
            console.error('API request error:', error);
        }
    };
    useEffect(() => {
        getProduct().then(() => setLoading(false)).catch(() => setLoading(false));
    }, [currentPage, selectedStartDate, selectedEndDate]);

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    return (
        <>
            <Helmet>
                <title>Cornucopia AI | Seller-Product Details</title>
            </Helmet>
            <FlexDiv style={{ paddingTop: '1.2rem', gap: isLaptop ? '1rem' : '0.1rem', justifyContent: 'flex-end' }}>
                <UserProfileSeller />
            </FlexDiv>
            <FlexDiv style={{ gap: '0.6rem', paddingLeft: isLargeLaptop ? '' : '1.5rem', paddingTop: '0.5rem' }}>
                <button style={{ display: 'contents' }} onClick={BackToProducts}>
                    <BackDiv>
                        <KeyboardBackspaceIcon sx={{ color: '#777777' }} />
                    </BackDiv>
                </button>
                <div>
                    <Typography sx={BackToProduct}>{BACK_TO_PRODUCT_LIST}</Typography>
                    <Typography sx={AddProduct}>{PRODUCT_DETAILS}</Typography>
                </div>
            </FlexDiv>
            {loading ? <div></div> :
                <FlexDiv style={{ marginTop: '0.3rem', justifyContent: 'flex-end', marginRight: isDesktopTwo ? '3rem' : '1rem' }}>
                    <IDDiv>{'#' + productDetails['id'].slice(0, 7)}</IDDiv>
                </FlexDiv>
            }
            <div style={{ marginBottom: '1rem', marginLeft: isTabFour ? '' : '1rem' }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress color='secondary' style={{ width: '27px', height: '25px' }} />
                    </div>
                    :
                    <>
                        {!isMobile &&
                            <>
                                <FlexDiv style={{ gap: '0.5rem', marginTop: '0.5rem', flexDirection: 'column' }}>
                                    <FlexDiv>
                                        <button style={{ display: 'contents' }}>
                                            <PriceBtn>
                                                {'$' + productDetails['price']}
                                            </PriceBtn>
                                        </button>
                                    </FlexDiv>
                                    {productDetails['demoVideo'] &&
                                        <>
                                            <button style={{ display: 'contents' }} onClick={openModal}>
                                                <LivePreview style={{ paddingTop: '0.75rem' }}>
                                                    <img src={GroupIcon} alt="" style={{ width: '0.90313rem', height: '1.0625rem', margin: '0rem 0.7rem 0rem 1.8rem' }} />
                                                    {LIVE_PREVIEW}
                                                </LivePreview>
                                            </button>
                                            <VideoModal open={isModalOpen} onClose={closeModal} videoURL={productDetails['demoVideo']} />
                                        </>
                                    }
                                </FlexDiv>
                            </>
                        }
                        <FlexDiv style={{ justifyContent: 'space-between', marginTop: '1rem', marginRight: isDesktopTwo ? '3rem' : '1rem' }}>
                            <div>
                                <FlexDiv style={{ gap: isDesktop ? '1.3rem' : '', flexDirection: isDesktop ? 'row' : 'column' }}>
                                    <Typography sx={Heading}>{productDetails['name']}</Typography>
                                    <Typography sx={HeadingNum} style={{ marginTop: '0.3rem' }}>(0)</Typography>
                                </FlexDiv>
                                <FlexDiv style={{ gap: '0.25rem', marginTop: '0.8rem' }}>
                                    {productDetails['category'].slice(0, 3).map((data) => (
                                        <FeaturesDiv key={data.id} style={{ padding: '0.2rem 0rem 0.2rem 0.5rem', gap: '0.2rem' }}>
                                            <img src={Coins} alt="" style={{ width: '0.6875rem', height: '0.625rem', marginTop: '0.2rem' }} />
                                            <Typography sx={FeaturesDivText} style={{ marginRight: '0.3rem' }}>{data}</Typography>
                                        </FeaturesDiv>
                                    ))}
                                </FlexDiv>
                            </div>
                            {isMobile &&
                                <FlexDiv style={{ gap: '0.5rem', marginTop: '0.5rem', flexDirection: isTabTwo ? 'row' : 'column' }}>
                                    <FlexDiv style={{ justifyContent: isTabTwo ? '' : 'flex-end' }}>
                                        <button style={{ display: 'contents' }}>
                                            <PriceBtn>
                                                {'$' + productDetails['price']}
                                            </PriceBtn>
                                        </button>
                                    </FlexDiv>
                                    {productDetails['demoVideo'] &&
                                        <>
                                            <button style={{ display: 'contents' }} onClick={openModal}>
                                                <LivePreview style={{ paddingTop: '0.75rem' }}>
                                                    <img src={GroupIcon} alt="" style={{ width: '0.90313rem', height: '1.0625rem', margin: '0rem 0.7rem 0rem 1.8rem' }} />
                                                    {LIVE_PREVIEW}
                                                </LivePreview>
                                            </button>
                                            <VideoModal open={isModalOpen} onClose={closeModal} videoURL={productDetails['demoVideo']} />
                                        </>
                                    }
                                </FlexDiv>
                            }
                        </FlexDiv>
                        <FlexDiv style={{ marginTop: '1.5rem', justifyContent: 'space-between', marginRight: isDesktopTwo ? '3rem' : '1rem', flexDirection: isDesktopThree ? 'row' : 'column' }}>
                            <div>
                                <Typography sx={summary} style={{ maxWidth: isDesktopThree ? '33rem' : '' }}>{productDetails['description']}</Typography>
                                {(!isTabThree && isMobile) && <img src={productDetails['images'][0]} alt="" style={{ borderRadius: '1.125rem', width: isDesktopTwo ? '34rem' : (isDesktopThree || !isDesktopFour) ? '32rem' : '45rem', height: "28.75rem", marginTop: '1rem' }} />}
                                <Grid container gap={'2.5rem'} style={{ marginTop: '1rem' }} justifyContent={(isTabThree || !isMobile) && 'center'}>
                                    {(isTabThree || !isMobile) && (!isSmallMobile) &&
                                        <Grid item lg={5} md={5} sm={5} xs={isMobileTwo ? 5 : 12} textAlign={!isMobileTwo && 'center'}>
                                            <Images src={productDetails['images'][0]} alt="" />
                                        </Grid>
                                    }
                                    {!isSmallMobile &&
                                        productDetails['images'].slice(1, productDetails['images'].length).map((image) => (
                                            <Grid item key={image} lg={3} md={3} sm={!isTabThree ? 3 : 5} xs={isMobileTwo ? 5 : 12} textAlign={!isMobileTwo && 'center'}>
                                                <Images src={image} alt="" />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                                {isSmallMobile &&
                                    <div style={{ width: '100%', marginTop: '1rem' }}>
                                        <ImageSlider images={productDetails['images']} />
                                    </div>
                                }
                            </div>
                            <div style={{ marginTop: isDesktopThree ? "" : "1.5rem" }}>
                                {isDesktopThree &&
                                    <>
                                        <FlexDiv style={{ justifyContent: isDesktopThree ? 'flex-end' : '' }}>
                                            <RevenueStats buyers={totalBuyers} totalRevenue={totalRevenue} />
                                        </FlexDiv>
                                        <div style={{ marginTop: isDesktopThree ? '0.5rem' : '' }}>
                                            <StatsChart productId={productId}/>
                                        </div>
                                    </>
                                }
                                <Typography sx={Heading} style={{ fontSize: '1.25rem', marginTop: '1rem' }}>Details</Typography>
                                <Typography sx={summary} style={{ maxWidth: isDesktopThree ? '26rem' : '' }}>{productDetails['description']}</Typography>
                                {
                                    Array.from({ length: (productDetails['category'].length / 3) + (productDetails['category'].length % 3 === 0 ? 0 : 1) }).map((_, index) => (
                                        <FlexDiv key={`Key-${index}`} style={{ gap: '0.5rem', marginTop: '0.5rem' }}>
                                            {productDetails['category'].slice(index * 3, (index + 1) * 3).map((data) => (
                                                <FeaturesDiv key={`key-${data}`} style={{ padding: '0.2rem 0rem 0.2rem 0.5rem', gap: '0.2rem' }}>
                                                    <img src={Trolley} alt="" style={{ width: '0.6875rem', height: '0.625rem', marginTop: '0.2rem' }} />
                                                    <Typography sx={FeaturesDivText} style={{ marginRight: '0.3rem' }}>{data}</Typography>
                                                </FeaturesDiv>
                                            ))}
                                        </FlexDiv>
                                    ))
                                }
                                {!isDesktopThree &&
                                    <FlexDiv style={{ flexDirection: isDesktopFour ? 'row' : 'column', justifyContent: isDesktopFour ? 'space-between' : '', marginTop: '1rem' }}>
                                        <FlexDiv>
                                            <RevenueStats buyers={totalBuyers} totalRevenue={totalRevenue} />
                                        </FlexDiv>
                                        <div style={{ marginTop: isDesktopFour ? '' : '1rem' }}>
                                            <StatsChart productId={productId}/>
                                        </div>
                                    </FlexDiv>
                                }
                            </div>
                        </FlexDiv>
                    </>
                }
                <FlexDiv style={{ marginTop: '2rem', justifyContent: 'space-between', marginRight: isDesktopTwo ? '3rem' : '1rem', flexDirection: isTab ? 'row' : 'column' }}>
                    <Typography sx={Heading}>{REVIEWS_AND_RATING}</Typography>
                    <FlexDiv style={{ marginTop: isTab ? '' : '1rem', justifyContent: (isTabThree || !isMobile) ? 'center' : '' }}>
                        <FlexDiv style={{ marginTop: '0.3rem' }}>
                            <DateChecker fromMarginRight="0.5rem" toMarginRight="0.5rem" toMarginLeft="0.5rem" onStartdateSelected={handleStartDateChange} onEndDateSelected={handleEndDateChange} />
                        </FlexDiv>
                        {(!isTabThree && isMobile) &&
                            <button style={{ display: 'contents' }}>
                                <FilterContainer style={{ marginLeft: '1rem' }}>
                                    <FlexDiv>
                                        <SearchIcon sx={{ color: '#8F8F8F', margin: '0.7rem 0rem 0rem 0.75rem', width: '1.5rem', height: '1.5rem' }} />
                                        <div style={{ width: '1.43rem', height: '1.3px', background: '#DEDEDE', transform: 'rotate(90deg)', margin: '1.4rem 0.3rem 0rem 0.5rem' }}></div>
                                    </FlexDiv>
                                    <h1 style={{ color: '#6D0085', fontFamily: 'Poppins', fontSize: '1rem', fontWeight: '500', marginTop: '0.8rem' }}>Filters</h1>
                                    <ExpandMoreIcon style={{ color: '#76108C', margin: '0.6rem 0.8rem 0rem 1rem' }} />
                                </FilterContainer>
                            </button>
                        }
                    </FlexDiv>
                    {(isTabThree || !isMobile) &&
                        <button style={{ display: 'contents' }}>
                            <FilterContainer style={{ marginTop: '1rem' }}>
                                <FlexDiv>
                                    <SearchIcon sx={{ color: '#8F8F8F', margin: '0.7rem 0rem 0rem 0.75rem', width: '1.5rem', height: '1.5rem' }} />
                                    <div style={{ width: '1.43rem', height: '1.3px', background: '#DEDEDE', transform: 'rotate(90deg)', margin: '1.4rem 0.3rem 0rem 0.5rem' }}></div>
                                </FlexDiv>
                                <h1 style={{ color: '#6D0085', fontFamily: 'Poppins', fontSize: '1rem', fontWeight: '500', marginTop: '0.8rem' }}>Filters</h1>
                                <ExpandMoreIcon style={{ color: '#76108C', margin: '0.6rem 0.8rem 0rem 1rem' }} />
                            </FilterContainer>
                        </button>
                    }
                </FlexDiv>
                <FlexDiv style={{ marginTop: '2rem', justifyContent: isTabTwo ? 'space-between' : 'center', flexDirection: isTabTwo ? 'row' : 'column', marginLeft: isTabTwo ? '' : '1rem' }}>
                    {reviews.length > 1 ? (
                        <div>
                            {isDesktopThree ? (

                                reviews.slice(0, -1).map((review) => (

                                    <div key={review._id} style={{ marginBottom: '1.5rem' }}>
                                        <ReviewCardSeller
                                            ratingPoints={review.rating}
                                            numOfStars={review.rating}
                                            comment={review.comment}
                                            name={review.user.fullName}
                                            date={review.createdAt}
                                        />
                                    </div>

                                ))
                            ) : (
                                reviews.slice(0, -1).map((review) => (
                                    <div style={{ marginBottom: '1.5rem' }}>
                                        <ReviewCard
                                            alignment={isMobileTwo ? 'center' : ''}
                                            rating={review.rating}
                                            comment={review.comment}
                                            fullName={review.user.fullName}
                                            date={review.createdAt} />
                                    </div>
                                ))
                            )}
                        </div>
                    ) : (
                        <div style={{ width: isDesktopThree ? '96%' : isTabTwo ? '100%' : '', marginRight: '1.5rem' }}>
                            <NoReview />
                        </div>
                    )}
                    {/* {!isSmallMobile &&
                        <FlexDiv style={{ flexDirection: 'column', gap: '1rem', marginRight: isDesktopTwo ? '3rem' : '1rem', justifyContent: isTabTwo ? '' : 'center', marginLeft: reviews.length > 1 ? '0rem' : '1rem' }}>
                            <FlexDiv style={{ flexDirection: isTabTwo ? 'column' : isMobileTwo ? 'row' : 'column', gap: isTabTwo ? '1rem' : '0.5rem', marginTop: isMobileTwo && !isTabTwo ? '1rem' : '', }}>
                                <PublishReview />
                                <RespondToReview />
                            </FlexDiv>
                            <FlexDiv style={{ flexDirection: isTabTwo ? 'column' : 'row', gap: isTabTwo ? '1rem' : '0.5rem' }}>
                                <FlexDiv style={{ gap: '0.5rem', marginLeft: '1rem', marginTop: isDesktopThree ? '' : '1rem' }}>
                                    <SaveAltIcon sx={{ color: '#C7C7C7', width: '1rem', height: '1rem', marginTop: '0.1rem' }} />
                                    <Typography sx={Download_Text}>{DOWNLOAD}</Typography>
                                </FlexDiv>
                                <FlexDiv style={{ gap: '0.5rem', marginLeft: '1rem', marginTop: isDesktopThree ? '' : '1rem' }}>
                                    <DeleteOutlineIcon sx={{ color: '#D95656', width: '1rem', height: '1rem', marginTop: '0.1rem' }} />
                                    <Typography sx={Download_Text}>{DELETE_REVIEW}</Typography>
                                </FlexDiv>
                            </FlexDiv>
                        </FlexDiv>
                    } */}
                </FlexDiv>
                {reviews.length > 1 &&
                    <FlexDiv style={{ justifyContent: 'center', marginTop: '2rem' }}>
                        <CustomPagination count={totalPages} page={currentPage} onChange={(event, newPage) => setCurrentPage(newPage)} />
                    </FlexDiv>
                }

            </div>
        </>
    )
}
export default SellerProductDetails;
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Typography, Button } from "@mui/material";
import Download from '../../assets/images/download.svg';
import { useMediaQuery } from "react-responsive";
import { FlexDiv } from "../../assets/styles/styles";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { CodeSection, DetailText } from "./Integration/constants";
import CopyIcon from '../../assets/images/copy-icon.png'
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const modalContentStyle = {
    background: '#ffff',
    padding: '1.5rem',
    borderRadius: '1.75rem',
    textAlign: 'center',
    position: 'relative',
    width: '25rem'
};

const clickToDownload = {
    color: 'var(--gray-500, #667085)',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem',
};

const downloadLinkStyle = {
    color: '#46137C',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginTop: '2rem',
};

const imageStyle = {
    borderRadius: '1.75rem',
    border: '8px solid var(--primary-50, #F9F5FF)',
    backgroundColor: 'var(--primary-100, #F4EBFF)',
};

const downloadButtonStyle = {
    display: 'flex',
    padding: '0.625rem 1.125rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    flex: '1 0 0',
    borderRadius: '0.4375rem',
    background: '#3F0065',
    marginTop: '2rem',
    '&:hover': {
        border: '1px solid #3F0065',
        color: '#3F0065',
        background: '#FFF'
    }
};

const downloadTextStyle = {
    color: 'var(--White, #FFF)',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '1.5rem',
    textTransform: 'none',
};

const ProductAddedModal = ({ open, onClose, heading, buttonText, productId = 'ABC123' }) => {
    const isMobile = useMediaQuery({ maxWidth: 450 });
    const userData = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [hovered, setHovered] = useState(false);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
        setSnackbarMsg('');
    };

    const handleButtonClick = () => {
        onClose();
    };

    const copyUrlToClipboard = () => {
        // Copy codeContent[selectedLanguage] to clipboard
        navigator.clipboard.writeText(`${productId}`)
            .then(() => {
                setSnackbarMsg('Copied to clipboard');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
            })
            .catch((error) => {
                console.error('Error copying code to clipboard:', error);
            });
    };

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                >
                    {snackbarMsg}
                </MuiAlert>
            </Snackbar>
            <Modal open={open} onClose={onClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ ...modalContentStyle, width: isMobile ? '80%' : '25rem' }}>
                    <img src={Download} alt="Download" style={imageStyle} />
                    <Typography variant="h6" gutterBottom style={downloadLinkStyle}>
                        {heading}
                    </Typography>
                    <Typography variant="body1" style={clickToDownload}>
                        Thank you for submitting your product to Cornucopia AI, we will review your posting and notify you when it has been approved. Estimated wait time is 1-2 days.
                    </Typography>
                    {/* <CodeSection style={{ marginTop: '2.19rem', marginBottom: '0rem', padding: '0px', width: '100%' }}>
                        <FlexDiv style={{ justifyContent: 'space-between', gap: '0.5rem', padding: '20px 20px 0px 20px', background: '#474e5a', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
                            <DetailText style={{ fontWeight: '800', color: '#fff', textAlign: 'left' }}>Product Id</DetailText>
                            <button
                                style={{
                                    width: '42px',
                                    height: '35px',
                                    borderRadius: '8px',
                                    backgroundColor: '#D9D9D9',
                                    cursor: 'pointer',
                                    outline: 'none',
                                    border: 'none',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '-0.5rem'
                                }}
                                onClick={copyUrlToClipboard}
                            >
                                <img src={CopyIcon} alt="Copy" />
                            </button>
                        </FlexDiv>
                        <pre style={{ padding: '15px 20px 15px 20px' }}>
                            <SyntaxHighlighter language='python' style={vscDarkPlus} customStyle={{ padding: '0rem', margin: '0rem' }}>
                                {productId ? productId : 'ABC123'}
                            </SyntaxHighlighter>
                        </pre>

                    </CodeSection> */}
                    <FlexDiv>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                ...downloadButtonStyle,
                                width: '100%',
                                border: hovered ? '1px solid #3F0065' : '',
                                background: hovered ? '#FFF' : '#3F0065',
                            }}
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            onClick={handleButtonClick}
                        >
                            <Typography variant="body1" style={{ ...downloadTextStyle, color: hovered ? '#3F0065' : '#FFF' }}>
                                {buttonText}
                            </Typography>
                        </Button>
                    </FlexDiv>
                </div>
            </Modal>
        </>
    );
};

export default ProductAddedModal;

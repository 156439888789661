import React, { useState, useEffect } from 'react';
import { NavBarDiv, CornucopiaIcon, FlexDiv, LoginBtn, JoinTheVentureBtn, NavDropdown, NavDropdownItem } from "../assets/styles/styles";
import cornucopia_landing_icon from "../assets/images/logo.jpeg";
import BuyerPurchasedProducts from '../Pages/BuyerPurchasedProducts/BuyerPurchasedProduct'
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from 'react-responsive'
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import UserProfileBox from "./UserProfileBox";
import io from "socket.io-client";
import { IP_ADD } from "../constants/api";
import { useNotification } from "../Context/NotificationContext";
import { logout } from "../Services/Authentication";
import { Container, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import LogoutModal from "./LogoutModal";
import { fetchNotificationsApi, deleteNotificationApi } from "../Services/Notification";
import ToggleButton from "./ToggleButton";
import { useLocation } from 'react-router-dom';

const ENDPOINT = `${IP_ADD}`;

const AUTH_HEADER_DATA = [
    {
        id: '1',
        name: 'Home',
        margin_right: '2rem',
        navigation_url: '/'
    },
    // {
    //     id: '2',
    //     name: 'My Products',
    //     margin_right: '2rem',
    //     navigation_url: '/my-products'
    // },
    {
        id: '2',
        name: 'Custom AI',
        margin_right: '2rem',
        navigation_url: '/custom-ai'
    },

    {
        id: '3',
        name: 'Marketplace',
        margin_right: '2rem',
        navigation_url: '/products'
    },
    // {
    //     id: '4',
    //     name: 'Sellers',
    //     margin_right: '0rem',
    //     navigation_url: '/sellers'
    // },
];

const NavBar = ({ header_data, header_button, mobile_nav_height, authenticated = false, }) => {

    const [isScrolled, setIsScrolled] = useState(false);
    const isDesktop = useMediaQuery({ minWidth: 1125 });
    const isTab = useMediaQuery({ maxWidth: 900 });
    const isMobile = useMediaQuery({ maxWidth: 605 });
    const isLaptop = useMediaQuery({ maxWidth: 690 });
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const userId = JSON.parse(localStorage.getItem('Cornucopia-user'));
    const navigate = useNavigate();
    const location = useLocation();
    const [isToggleVisible, setIsToggleVisible] = useState(true);
    const { notification, notificationUpdated, setNotification, setNotificationUpdated, setPaymentFail, setPaymentSuccess, setNewProductId } = useNotification();
    const {
        chatId,
        chatsCopy,
        setFetchAgain,
        setMessages,
        socket,
        setSocket
    } = useNotification();
    const chatsCopyRef = useRef(chatsCopy);
    const chatIdRef = useRef(chatId);
    useEffect(() => {
        chatsCopyRef.current = chatsCopy;
    }, [chatsCopy]);

    useEffect(() => {
        chatIdRef.current = chatId;
    }, [chatId]);

    const handleNavigation = (navigation_url) => {
        navigate(navigation_url);
    };

    useEffect(() => {
        const hideToggleButton = location.pathname === '/login' || location.pathname === '/buyerseller' || location.pathname === '/signup' || location.pathname === '/signup-seller';
        setIsToggleVisible(!hideToggleButton);
    }, [location]);

    useEffect(() => {
        const handleScroll = () => {
            // Set state based on scroll position
            setIsScrolled(window.scrollY > 0);
        };

        // Add event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const authenticate = () => {
        let user = localStorage.getItem('Cornucopia-user');
        user = JSON.parse(user);
        return user?.response?.role === 'buyer' ? true : authenticated;
    }

    const dataToDisplay = () => {
        return authenticate() ? AUTH_HEADER_DATA : header_data;
    }

    const handleNavigateSettings = () => {
        navigate('/settings');
    }

    const handleNavigateMyProducts = () => {
        navigate('/my-products');
    }

    const handlelogout = async (token) => {
        try {
            setLoading(true)
            const token = localStorage.getItem('refresh-token');
            await logout(token);
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('access-token-expiry');
            localStorage.removeItem('refresh-token-expiry');
            localStorage.removeItem('Cornucopia-user');
            setLoading(false);
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };
    const handleCornucopiaIconClick = () => {
        navigate('/');
    };
    const handlePaymentError = async (newMessageReceived) => {

        if (newMessageReceived.type === "payment") {
            console.log('payment failed', newMessageReceived.message)
            setPaymentFail(true);
        }
    };

    const handleUserSuspended = async (newMessageReceived) => {

        if (userId.response.id === newMessageReceived) {
            setSnackbarMsg("Your account has been suspended");
            setSnackbarSeverity('error');
            setOpen(true);
            setTimeout(() => {
                handlelogout();
            }, 2500);
        }
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setSnackbarMsg('');
    };
    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setLoading(false);
        setIsOpen(false);
    };
    useEffect(() => {
        const handleNotification = async (newMessageReceived) => {
            const { chat } = newMessageReceived.data;
            if (chat && newMessageReceived.type === "message") {
                let idMatched = false;
                // Check if the new message chat ID matches any chat in
                chatsCopyRef.current.forEach(chat => {
                    // Compare _id with newMessageRecieved.chat._id
                    if (chat._id === newMessageReceived.data.chat._id) {
                        idMatched = true;
                    }
                    if (!idMatched) {
                        setFetchAgain(true);
                    }
                });
                if (chatsCopyRef.current.length === 0) {
                    setFetchAgain(true);
                }

                const notificationApiResult = await fetchNotificationsApi(userId.response.id);

                if (notificationApiResult.success && (!chatIdRef.current || chatIdRef.current !== chat._id)) {
                    setNotification([...notificationApiResult.data]);
                    setFetchAgain(true);
                } else {
                    setMessages((prevMessages) => [...prevMessages, newMessageReceived.data]);
                    const latestNotification = notificationApiResult.data[notificationApiResult.data.length - 1];
                    let notificationId;
                    if (latestNotification) {
                        notificationId = latestNotification._id;
                    }

                    // Delete the latest notification
                    const deleteNotificationResult = await deleteNotificationApi(notificationId);

                    if (!deleteNotificationResult.success) {
                        console.log("Error deleting notification", deleteNotificationResult.error);
                    }
                }
            } else if (newMessageReceived.type === "payment") {
                if (userId.response.id === newMessageReceived.metadata.buyer_id)
                    setPaymentSuccess(true);
            } else if (newMessageReceived.type === 'webPayment') {
                setNewProductId(newMessageReceived.metadata.product.id);
                const notificationApiResult = await fetchNotificationsApi(userId.response.id);
                setNotification([...notificationApiResult.data]);
                setFetchAgain(true);
            }
        };

        const user = localStorage.getItem("Cornucopia-user");
        if (user) {
            const newSocket = io(ENDPOINT);
            newSocket.emit("setup", userId.response);
            newSocket.on("notification", handleNotification);
            newSocket.on("error", handlePaymentError);
            newSocket.on("userSuspended", handleUserSuspended);


            setSocket(newSocket);

        }

        return () => {
            // Cleanup socket when component unmounts
            if (socket && socket.connected) {
                socket.disconnect();
            }
        };
    }, []);
    const fetchData = async () => {
        const notificationApiResult = await fetchNotificationsApi(userId.response.id);

        if (notificationApiResult.success) {
            setNotification([...notificationApiResult.data]);
        } else {
            console.log("Error in notification fetching", notificationApiResult.error);
        }
    };

    const rigthSideMargin = () => {
        if (authenticate()) {
            if (isMobile) {
                return "1rem";
            } else {
                return "8rem";
            }
        }
        return "2rem";
    }

    useEffect(() => {
        if (userId)
            fetchData();
    }, []);

    useEffect(() => {
        setNotificationUpdated(true);
    }, [notification]);

    useEffect(() => {
        return () => {
            // Cleanup socket when component unmounts
            if (socket) {
                socket.disconnect();
            }
        };
    }, [socket]);

    return (
        <>
            <Container maxWidth={false} sx={{ maxWidth: '1800px' }} disableGutters>

                <LogoutModal isOpen={isOpen} onClose={closeModal} onVerifyEmail={handlelogout} isVerifyingEmail={loading} heading='Logging Out' buttonText='Logout' />

                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleClose}
                        severity={snackbarSeverity}
                    >
                        {snackbarMsg}
                    </MuiAlert>
                </Snackbar>
                <NavBarDiv className={isScrolled ? 'scrolled' : ''}>
                    <CornucopiaIcon
                        onClick={handleCornucopiaIconClick}
                        src={cornucopia_landing_icon}
                        alt=""
                        style={{ cursor: "pointer", marginLeft: isDesktop ? '3rem' : '1rem', cursor: 'pointer', width: isMobile && '11rem', height: isMobile && '2.75rem' }}
                    />
                    {isDesktop ?
                        <FlexDiv style={{ marginRight: "4rem" }}>
                            {dataToDisplay().map((data) => (
                                <div style={{ marginTop: "2.3rem", height: "1.1875rem", marginRight: `${data.margin_right}` }} key={data.id}>
                                    <LoginBtn onClick={() => handleNavigation(data.navigation_url)}>{data.name}</LoginBtn>
                                </div>
                            ))}
                            {!authenticate() &&
                                <JoinTheVentureBtn onClick={() => handleNavigation(header_button.navigation_url)}>
                                    {header_button.label}
                                </JoinTheVentureBtn>
                            }
                            {authenticate() &&
                                <div style={{ marginTop: '1.75rem', marginLeft: '1rem' }}>
                                    <UserProfileBox openModal={openModal} mobileNavOpen={mobileNavOpen} setMobileNavOpen={setMobileNavOpen} isAMobile={isMobile} />
                                </div>
                            }
                            <NavDropdown style={{ height: mobileNavOpen ? authenticate() ? '120px' : `0px` : '0px', width: mobileNavOpen ? '290px' : '0px', border: mobileNavOpen ? '1px solid #9803fa' : '0', zIndex: 41, top: authenticate() && '4.9rem', right: '18rem' }}>

                                {authenticate() &&
                                    <>
                                        <NavDropdownItem style={{ borderBottom: "#fff", marginTop: '-0.25rem' }}><LoginBtn onClick={handleNavigateSettings}>Settings</LoginBtn></NavDropdownItem>
                                        <NavDropdownItem style={{ borderBottom: "#fff", marginTop: '-0.25rem' }}><LoginBtn onClick={handleNavigateMyProducts}>My Products</LoginBtn></NavDropdownItem>
                                        <NavDropdownItem style={{ borderBottom: "#fff", marginTop: '-0.5rem', }}><LoginBtn onClick={openModal} style={{
                                            color: '#DB0000', transition: 'opacity 0.3s',
                                            opacity: 1,
                                        }}
                                            onMouseOver={(e) => e.target.style.opacity = 0.8}
                                            onMouseOut={(e) => e.target.style.opacity = 1}>Log out</LoginBtn></NavDropdownItem>
                                    </>
                                }
                            </NavDropdown>
                            {isToggleVisible && <ToggleButton />}
                        </FlexDiv>
                        :
                        <>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                {!authenticate() && !isLaptop && isToggleVisible && < ToggleButton margintop='1.25rem' />}
                                <button style={{ display: 'contents' }} onClick={() => { setMobileNavOpen(!mobileNavOpen) }}>
                                    {!authenticate() && <MenuIcon style={{ color: 'rgba(152,4,250,255)', margin: '2rem 2rem 0rem 0rem' }} />}
                                </button>
                            </div>
                            {authenticate() && <div style={{ marginTop: '1.5rem', marginRight: '1rem' }}><UserProfileBox mobileNavOpen={mobileNavOpen} setMobileNavOpen={setMobileNavOpen} isAMobile={isMobile} /></div>}
                            <NavDropdown style={{
                                height: mobileNavOpen ? authenticate() ? (isLaptop ? '260px' : '250px') : isToggleVisible ? `260px` : '215px' : '0px', width: mobileNavOpen ? '250px' : '0px',
                                border: mobileNavOpen ? '1px solid #9803fa' : '0',
                                zIndex: 41,
                                top: authenticate() && '4.9rem',
                                right: rigthSideMargin()
                            }}>
                                {
                                    dataToDisplay().map((data) => (
                                        <NavDropdownItem key={data.id}><LoginBtn onClick={() => handleNavigation(data.navigation_url)}>{data.name}</LoginBtn></NavDropdownItem>
                                    ))
                                }
                                {/* {isLaptop && isToggleVisible &&
                                    <NavDropdownItem ><ToggleButton /></NavDropdownItem>
                                } */}
                                {authenticate() && <NavDropdownItem style={{ borderBottom: "#fff", marginTop: '1rem' }}><LoginBtn opacityVal='1' onClick={openModal} style={{ color: '#DB0000' }}>Log out</LoginBtn></NavDropdownItem>}
                                {!authenticate() &&
                                    <NavDropdownItem style={{ borderBottom: "#fff" }}>
                                        <JoinTheVentureBtn style={{ marginLeft: '0rem', marginTop: '0rem' }} onClick={() => handleNavigation(header_button.navigation_url)}>
                                            {header_button.label}
                                        </JoinTheVentureBtn>
                                    </NavDropdownItem>
                                }
                            </NavDropdown>
                        </>
                    }
                </NavBarDiv >
                {/* Adjust height of the container to prevent content from blocking */}
                <div style={{ height: '83px' }}></div>

                {/* Fixed position ToggleButton for mobile */}

                {isToggleVisible && isLaptop && (
                    <div
                        style={{
                            position: 'fixed',
                            bottom: '20px', // Adjust this value as needed
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 9999
                        }}
                    >
                        <ToggleButton />
                    </div>
                )}
            </Container >
        </>
    )
}
export default NavBar;


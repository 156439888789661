import axios from 'axios'
import { IP_ADD, LOCAL_HOST } from '../constants/api'
import { format } from 'date-fns'
import qs from 'qs'

const ITEMS_PER_PAGE = 16

export const getProductDetails = async productId => {
  try {
    const res = await axios.get(`${IP_ADD}/api/products/${productId}`)
    return res.data
  } catch (error) {
    console.error('Error Retrieving product:', error)
    throw error
  }
}

export const getCustomProductDetails = async productId => {
  try {
    const res = await axios.get(`${IP_ADD}/api/products/custom-ai/${productId}`)
    console.log(res)
    return res.data
  } catch (error) {
    console.error('Error Retrieving product:', error)
    throw error
  }
}

function formDataToJson (formData) {
  const json = {}
  formData.forEach((value, key) => {
    if (json.hasOwnProperty(key)) {
      // If the key already exists, convert it to an array if it's not one
      if (!Array.isArray(json[key])) {
        json[key] = [json[key]]
      }
      // Push the new value into the array
      json[key].push(value)
    } else {
      // Otherwise, just set the value
      json[key] = value
    }
  })
  return json
}

export const addCustomProduct = async (formData, setProductId) => {
  try {
    const storedUser = localStorage.getItem('Cornucopia-user')
    const user = JSON.parse(storedUser)
    const userId = user.response.id
    formData.append('user', userId)
    console.log('before calling /products', formData)
    console.log(formDataToJson(formData))
    const response = await axios.post(
      `${IP_ADD}/api/products/custom-ai`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    console.log('response: ', response)
    setProductId(response.data.response.id)
    if (response.statusText === 'Created') {
      return true
    }
  } catch (error) {
    console.error('Custom Product creation error:', error)
    throw error
  }
}

export const addProduct = async (formData, setProductId) => {
  try {
    const storedUser = localStorage.getItem('Cornucopia-user')
    const user = JSON.parse(storedUser)
    const userId = user.response.id
    formData.append('user', userId)
    console.log('before calling /products', formData)
    console.log(formDataToJson(formData))
    const response = await axios.post(`${IP_ADD}/api/products/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    console.log('response: ', response)
    setProductId(response.data.response.id)
    if (response.statusText === 'Created') {
      return true
    }
  } catch (error) {
    console.error('Product creation error:', error)
    throw error
  }
}

export const addCustomAI = async (formData, setProductId) => {
  try {
    const storedUser = localStorage.getItem('Cornucopia-user')
    const user = JSON.parse(storedUser)
    const userId = user.response.id
    formData.append('user', userId)

    console.log('before calling /products', formData)
    console.log(formDataToJson(formData))
    const response = await axios.post(
      `${IP_ADD}/api/products/custom-ai`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    console.log('response: ', response)
    setProductId(response.data.response.id)
    if (response.statusText === 'Created') {
      return true
    }
  } catch (error) {
    console.error('Product creation error:', error)
    throw error
  }
}

export const updateProduct = async (productId, formData) => {
  try {
    const response = await axios.put(
      `${IP_ADD}/api/products/${productId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return response.status === 200
  } catch (error) {
    console.error('Error updating product:', error)
    throw error
  }
}

export const getProducts = async (
  pageSize,
  searchQuery = '',
  selectedFilters,
  currentPage
) => {
  try {
    const storedUser = localStorage.getItem('Cornucopia-user')
    const user = JSON.parse(storedUser)
    const userId = user.response.id
    let response
    if (selectedFilters.length === 0) {
      response = await axios.get(
        `${IP_ADD}/api/products/seller/${userId}?limit=${pageSize}&name=${searchQuery}&page=${currentPage}`
      )
    } else {
      response = await axios.get(
        `${IP_ADD}/api/products/seller/${userId}?limit=${pageSize}&name=${searchQuery}&category=["${selectedFilters.join(
          '","'
        )}"]`
      )
    }
    if (response.data.status) {
      return response.data.response
    }
  } catch (error) {
    console.error('API request error:', error)
    throw error
  }
}

export const deleteProduct = async productId => {
  try {
    await axios.delete(`${IP_ADD}/api/products/${productId}`)
  } catch (error) {
    console.error('Error deleting product:', error)
    throw error
  }
}
export const getAllProducts = async productId => {
  try {
    const productUrl = `${IP_ADD}/api/products/${productId}`
    const reviewsUrl = `${IP_ADD}/api/products/reviews/${productId}?limit=3&page=1`

    const [productResponse, reviewsResponse] = await Promise.all([
      axios.get(productUrl),
      axios.get(reviewsUrl)
    ])

    if (productResponse?.data?.response) {
      const productDetails = productResponse?.data?.response
      return { productDetails }
    }

    return { error: 'Product not found' }
  } catch (error) {
    console.error('API request error:', error)
    return { error: 'API request failed' }
  }
}

export const getReviews = async (
  productId,
  currentPage = 1,
  selectedStartDate,
  selectedEndDate,
  setCurrentPage = () => {},
  limit = '3'
) => {
  try {
    let reviewsUrl
    if (selectedStartDate && selectedEndDate) {
      const startDate = selectedStartDate
        ? format(selectedStartDate, 'yyyy-MM-dd')
        : ''
      const endDate = selectedEndDate
        ? format(selectedEndDate, 'yyyy-MM-dd')
        : ''
      reviewsUrl = `${IP_ADD}/api/products/reviews/${productId}?limit=${limit}&page=${currentPage}&startDate=${startDate}&endDate=${endDate}`
    } else {
      reviewsUrl = `${IP_ADD}/api/products/reviews/${productId}?limit=${limit}&page=${currentPage}`
    }
    const reviewsResponse = await axios.get(reviewsUrl)
    if (reviewsResponse?.data?.response) {
      const reviews = reviewsResponse?.data?.response
      const totalPages = reviews[reviews.length - 1].totalPages
      // Call the setCurrentPage function with the adjusted value
      setCurrentPage(currentPage > totalPages ? totalPages : currentPage)
      return { reviews, totalPages }
    }
    return { error: 'Reviews not found' }
  } catch (error) {
    console.error('API request error:', error)
    return { error: 'API request failed' }
  }
}

export const fetchCustomProducts = async ({
  searchQuery,
  selectedCategory,
  currentPage,
  limit,
  selected,
  sortBy
}) => {
  console.log('Fetch')
  try {
    let response

    let apiUrl = `${IP_ADD}/api/products/custom-ai/?limit=${limit}&isBuyer=true&page=${currentPage}&sortby=${sortBy}`
    console.log(apiUrl)

    if (searchQuery !== '') {
      apiUrl += `&name=${searchQuery}`
    }

    if (selectedCategory.length > 0) {
      apiUrl += `&category=["${selectedCategory.join('","')}"]`
    }

    // if (selected) {
    //   apiUrl += `&type=${selected}`
    // }

    response = await axios.get(apiUrl)

    console.log(response)
    return {
      products: response.data.response.results,
      totalPages: response.data.response.totalPages
    }
  } catch (error) {
    console.error('Error fetching products:', error)
    throw error
  }
}

export const fetchProducts = async ({
  searchQuery,
  minPrice,
  maxPrice,
  selectedCategory,
  currentPage,
  limit,
  selected,
  sortBy
}) => {
  console.log('fetch Products')
  try {
    let response

    let apiUrl = `${IP_ADD}/api/products/?limit=${limit}&isBuyer=true&page=${currentPage}&sortby=${sortBy}&verified=true`
    console.log('Products URL: ', apiUrl)

    if (searchQuery !== '') {
      apiUrl += `&name=${searchQuery}`
    }

    if (minPrice !== 0) {
      apiUrl += `&minPrice=${minPrice}`
    }

    if (maxPrice !== 10000) {
      apiUrl += `&maxPrice=${maxPrice}`
    }

    if (selectedCategory.length > 0) {
      apiUrl += `&category=["${selectedCategory.join('","')}"]`
    }

    if (selected) {
      apiUrl += `&type=${selected}`
    }

    response = await axios.get(apiUrl)

    return {
      products: response.data.response.results,
      totalPages: response.data.response.totalPages
    }
  } catch (error) {
    console.error('Error fetching products:', error)
    throw error
  }
}

export const fetchBuyerPurchasedProducts = async ({
  userId,
  currentPage,
  selectedStartDate,
  selectedEndDate,
  selectedFilters,
  searchQuery,
  type
}) => {
  try {
    let response
    let apiUrl = `${IP_ADD}/api/products/buyer/${userId}?limit=7&page=${currentPage}&name=${searchQuery}`

    if (type) {
      apiUrl += `&type=${type}`
    }

    if (selectedStartDate && selectedEndDate && selectedFilters.length === 0) {
      const startDate = format(selectedStartDate, 'yyyy-MM-dd')
      const endDate = format(selectedEndDate, 'yyyy-MM-dd')
      apiUrl += `&startDate=${startDate}&endDate=${endDate}`
    } else if (
      selectedStartDate &&
      selectedEndDate &&
      selectedFilters.length !== 0
    ) {
      const startDate = format(selectedStartDate, 'yyyy-MM-dd')
      const endDate = format(selectedEndDate, 'yyyy-MM-dd')
      apiUrl += `&category=["${selectedFilters.join(
        '","'
      )}"]&startDate=${startDate}&endDate=${endDate}`
    } else if (selectedFilters.length !== 0) {
      apiUrl += `&category=["${selectedFilters.join('","')}"]`
    }

    response = await axios.get(apiUrl)

    if (response.data.status === 200) {
      return {
        products: response.data.response.results,
        totalPages: response.data.response.totalPages
      }
    } else {
      console.error('Failed to fetch data from the API', response)
      return { products: [], totalPages: 0 }
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export const fetchLandingProducts = async (limit, selected) => {
  try {
    const response = await axios.get(
      `${IP_ADD}/api/products/?limit=${limit}&type=${selected}`
    )
    return response.data.response.results
  } catch (error) {
    console.error('Error fetching products:', error)
    throw error
  }
}

export const uploadCustomProductDetails = async (userId, formDetails) => {
  try {
    const response = await axios.put(
      `${IP_ADD}/api/products/buyer/${userId}/uploadCustomProductDetails`,
      formDetails,
      {
        headers: {
          'Content-Type': 'multipart/form-data' // Set content type to multipart/form-data
        }
      }
    )

    return response.status === 200 // Return true if the response status is 200
  } catch (error) {
    console.error('Error updating product:', error) // Log the error
    throw error // Rethrow the error for further handling
  }
}
export const getAllReviews = async (
  productId,
  selectedStartDate,
  selectedEndDate,
  limit = '3'
) => {
  try {
    let currentPage = 1;
    let allReviews = [];
    let totalPages = 1; // Initialize totalPages with a default value

    do {
      let reviewsUrl;

      if (selectedStartDate && selectedEndDate) {
        const startDate = selectedStartDate
          ? format(selectedStartDate, 'yyyy-MM-dd')
          : '';
        const endDate = selectedEndDate
          ? format(selectedEndDate, 'yyyy-MM-dd')
          : '';
        reviewsUrl = `${IP_ADD}/api/products/reviews/${productId}?limit=${limit}&page=${currentPage}&startDate=${startDate}&endDate=${endDate}`;
      } else {
        reviewsUrl = `${IP_ADD}/api/products/reviews/${productId}?limit=${limit}&page=${currentPage}`;
      }

      const reviewsResponse = await axios.get(reviewsUrl);

      if (reviewsResponse?.data?.response) {
        const reviews = reviewsResponse.data.response;
        totalPages = reviews[reviews.length - 1]?.totalPages || 1;

        // Append reviews to the allReviews array
        allReviews = [...allReviews, ...reviews];
      } else {
        break; // If no response, exit the loop
      }

      currentPage++; // Increment to the next page
    } while (currentPage <= totalPages);

    return { reviews: allReviews, totalPages };
  } catch (error) {
    console.error('API request error:', error);
    return { error: 'API request failed' };
  }
};
import { React, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { uploadCustomProductDetails } from "../../../Services/Products";
import { useNavigate } from 'react-router-dom';

const SOW = ({ sellerId, inquireOpen, setInquireOpen }) => {
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState("");
    const [industry, setIndustry] = useState('');
    const [budget, setBudget] = useState("");
    const [description, setDescription] = useState("");
    const [features, setFeatures] = useState("");
    const userData = (localStorage.getItem('access-token'));

    const handleInquireModalClose = () => {
        setInquireOpen(false);
    };

    const handleFormData = async (sellerId) => {
        if (!userData) {
            alert("Please log in or create an account to proceed.");
            navigate("/signup", {
                state: { redirectTo: window.location.pathname },
            });
            return;
        }
        const formData = new FormData();
        formData.append('sellerId', sellerId);
        formData.append('companyName', companyName);
        formData.append('industry', industry);
        formData.append('budget', budget);
        formData.append('description', description);
        formData.append('features', features);
        console.log(sellerId, companyName, industry, budget, description)
        const data = await uploadCustomProductDetails(sellerId, formData);

        setCompanyName("");
        setIndustry("");
        setBudget("");
        setDescription("");
        setFeatures("");
    };

    const handleChange = (event) => {
        setIndustry(event.target.value);
    };

    const handleSwitchForm = (form) => {
        setActiveForm(form);
    };

    return (
        <Dialog
            open={inquireOpen}
            onClose={handleInquireModalClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    handleFormData(sellerId);
                },
            }}
            sx={{
                maxWidth: '50%',
                maxHeight: 'vh',
                margin: 'auto'
            }}
            fullWidth
        >
            <DialogTitle
                sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '25px' }}
            >
                Inquire about AI Tool
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    sx={{ textAlign: 'center', fontWeight: 'bold', mb: 1 }}
                >
                    We will reach out to you to discuss how we can help.
                </DialogContentText>

                <DialogContentText sx={{ fontWeight: 'bold', mb: 1, color: 'black' }}>
                    Company Name
                </DialogContentText>
                <TextField
                    sx={{ width: '55ch', mb: 2 }}
                    label="Company Name"
                    name="companyname"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    autoFocus
                />

                <DialogContentText sx={{ fontWeight: 'bold', mb: 1, color: 'black' }}>
                    Industry
                </DialogContentText>
                <Box sx={{ width: '55ch', mb: 2 }}>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            label="Industry"
                            id="demo-simple-select"
                            value={industry}
                            onChange={handleChange}
                        >
                            <MenuItem value={"Business"}>Business</MenuItem>
                            <MenuItem value={"Learning"}>Learning</MenuItem>
                            <MenuItem value={"Analytics"}>Analytics</MenuItem>
                            <MenuItem value={"NLP"}>NLP</MenuItem>
                            <MenuItem value={"E-commerce"}>E-commerce</MenuItem>
                            <MenuItem value={"Computer Vision"}>Computer Vision</MenuItem>
                            <MenuItem value={"Speech Processing"}>Speech Processing</MenuItem>
                            <MenuItem value={"Audio Processing"}>Audio Processing</MenuItem>
                            <MenuItem value={"Real Estate"}>Real Estate</MenuItem>
                            <MenuItem value={"Retail"}>Retail</MenuItem>
                            <MenuItem value={"Food Services"}>Food Services</MenuItem>
                            <MenuItem value={"IoT"}>IoT</MenuItem>
                            <MenuItem value={"Gaming"}>Gaming</MenuItem>
                            <MenuItem value={"Robotics"}>Robotics</MenuItem>
                            <MenuItem value={"Augmented Reality"}>Augmented Reality</MenuItem>
                            <MenuItem value={"Virtual Reality"}>Virtual Reality</MenuItem>
                            <MenuItem value={"Education Technology"}>Education Technology</MenuItem>
                            <MenuItem value={"Predictive Maintenance"}>Predictive Maintenance</MenuItem>
                            <MenuItem value={"Recommender Systems"}>Recommender Systems</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <DialogContentText sx={{ fontWeight: 'bold', mb: 1, color: 'black' }}>
                    Desired Budget
                </DialogContentText>
                <TextField
                    sx={{ width: '55ch', mb: 2 }}
                    label="Desired Budget"
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                    name="epp"
                />

                <DialogContentText sx={{ fontWeight: 'bold', mb: 1, color: 'black' }}>
                    Describe How You Would like a Custom Built AI Tool to Help Your Business
                </DialogContentText>
                <TextField
                    sx={{ width: '55ch', mb: 2 }}
                    name="description"
                    label="Explain here..."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    rows={4}
                />

                <DialogContentText sx={{ fontWeight: 'bold', mb: 1, color: 'black' }}>
                    Key Features Desired
                </DialogContentText>
                <TextField
                    sx={{ width: '55ch', mb: 2 }}
                    name="key-features"
                    value={features}
                    onChange={(e) => setFeatures(e.target.value)}
                    label="Key Features Desired"
                    multiline
                    rows={4}
                />
            </DialogContent>

            <DialogActions>
                <Button variant="contained" type="submit" sx={{ backgroundColor: "#9900fd" }}>
                    {'Submit Inquiry'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SOW;

import React from 'react';
import Modal from "react-modal";
import CircularProgress from "@mui/material/CircularProgress";
import { CardElement } from "@stripe/react-stripe-js";
import CardIcon from "../assets/images/card_icon.svg";
import { CANCEL } from '../constants/text';
import { useMediaQuery } from "react-responsive";

const enterCard = {
    color: 'var(--gray-500, #667085)',
    fontFamily: 'Poppins',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem',
    marginTop: '0.5rem',
    marginBottom: '2.3rem'
};

const PurchaseProduct = ({ 
    isOpen, 
    onClose, 
    onBuyProduct, 
    loading 
}) => {
    const isSmallMobile = useMediaQuery({ maxWidth: 450 });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Subscribe Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000
                },
                content: {
                    width: isSmallMobile ? '95%' : '400px',
                    height: isSmallMobile ? 'max-content' : '350px',
                    margin: 'auto',
                    borderRadius: '0.75rem',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: '1.5rem',
                    zIndex: 1001,
                    inset: isSmallMobile ? '0px' : '40px',
                    top: isSmallMobile ? '25%' : '',
                    left: isSmallMobile ? '2.5%' : ''
                },
            }}
        >
            <img src={CardIcon} alt="" style={{
                width: '3.5rem',
                height: '3.5rem',
                borderRadius: '0.5rem',
                border: '8px solid var(--primary-50, #F9F5FF)',
                background: 'var(--primary-100, #F4EBFF)',
                marginBottom: '1.25rem',
            }} />
            <h2 style={{
                color: '#46137C',
                fontFamily: 'Poppins',
                fontSize: '1.5rem',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                display: 'flex',
            }}>
                Purchase Product
            </h2>
            <div style={enterCard}>Enter your card details</div>
            <CardElement
                options={{
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }}
            />
            <div style={{ display: 'flex', marginTop: '1.2rem' }}>
                <button
                    onClick={onClose}
                    style={{
                        width: '50%',
                        borderRadius: '0.4375rem',
                        background: '#EDEDED',
                        color: '#8F8F8F',
                        fontFamily: 'Poppins',
                        fontSize: '0.8125rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        marginTop: '3rem',
                        border: 'none',
                        display: 'block',
                        padding: '8px',
                        marginRight: '0.75rem'
                    }}
                >
                    {CANCEL}
                </button>
                <button
                    onClick={onBuyProduct}
                    style={{
                        width: '50%',
                        borderRadius: '0.4375rem',
                        background: '#3F0065',
                        color: '#FFF',
                        fontFamily: 'Poppins',
                        fontSize: '0.8125rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        marginTop: '3rem',
                        border: 'none',
                        display: 'block',
                        padding: '8px',
                        transition: 'opacity 0.3s',
                        opacity: 1,
                    }}
                    onMouseOver={(e) => e.target.style.opacity = 0.8}
                    onMouseOut={(e) => e.target.style.opacity = 1}
                >
                    {!loading ? 'Buy Now' : <CircularProgress color="secondary" style={{ width: '15px', height: '15px' }} />}
                </button>
            </div>
        </Modal>
    );
};

export default PurchaseProduct;
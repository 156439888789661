import React, { useState, useEffect } from 'react';
import { FlexDiv } from '../../assets/styles/styles';
import { useMediaQuery } from "react-responsive";
import styled from "@emotion/styled";
import ChatList from '../Inbox/ChatList';
import ChatWindow from '../Inbox/ChatWindow';
import VerticalDivider from '../../assets/images/vertical_divider.png';
import ChatSearch from '../Inbox/ChatSearch';
import UserProfileSeller from './UserProfileSeller';
import { IP_ADD } from '../../constants/api';
import axios from 'axios';
import { useNotification } from '../../Context/NotificationContext';
import { INBOX } from '../../constants/text';
import { Helmet } from 'react-helmet';

const HeaderText = styled.div`
    color: #6D0085;
    font-family: Poppins;
    font-size: 1.4375rem;
    font-weight: 500;
    margin-top: 1rem;
`;

const TokenExpired = (tokenDate) => {
    const convertedTime = new Date(tokenDate);
    const currentTime = new Date();
    if (currentTime >= convertedTime) {
        return true;
    }
    return false;
}

const SellerInbox = () => {
    const isDesktop = useMediaQuery({ minWidth: 1000 });
    const [searchTerm, setSearchTerm] = useState('');
    const isTab = useMediaQuery({ minWidth: 950 });
    const [isExpired, setIsExpired] = useState(true);

    const {
        chatId,
        setChatId,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        chatAvailable,
        setChatAvailable,
        chatsCopy,
        setChatsCopy,
        fetchAgain,
        setFetchAgain,
        chatListClicked,
        setChatListClicked,
        messages,
        setMessages,
        socket,
        setSocket
    } = useNotification();

    // const [notification, setNotification] = useState([]);
    // const [notificationUpdated, setNotificationUpdated]= useState(false);
    const { notification, notificationUpdated, setNotification, setNotificationUpdated } = useNotification();
    const refreshtoken = async () => {
        try {
            const token = localStorage.getItem('refresh-token');
            const response = await axios.post(`${IP_ADD}/api/auth/refresh-tokens`, {
                refreshToken: token
            });
            if (response.status === 200) {
                localStorage.setItem('access-token', response.data.response.response.tokens.access.token);
                localStorage.setItem('refresh-token', response.data.response.response.tokens.refresh.token);
                localStorage.setItem('access-token-expiry', response.data.response.response.tokens.access.expires);
                localStorage.setItem('refresh-token-expiry', response.data.response.response.tokens.refresh.expires);
                setIsExpired(false);
            }
        } catch {
            console.log('error')
        }
    }

    useEffect(() => {
        const accessExpiryToken = localStorage.getItem('access-token-expiry');
        if (TokenExpired(accessExpiryToken)) {
            refreshtoken();
        } else {
            setIsExpired(false);
        }
    }, [])
    useEffect(() => {
        return () => {
            setChatId(null); // Reset chatId when leaving the InboxBuyer page
            setChatListClicked(false);

        };
    }, []);
    return !isExpired ? (
        <>
            <Helmet>
                <title>Cornucopia AI | Inbox-Seller</title>
            </Helmet>

            <div style={{ marginBottom: '-200px' }}>
                <FlexDiv
                    style={{ marginLeft: isDesktop ? '0rem' : '1rem', fontSize: isTab ? '' : '1.5rem', justifyContent: 'space-between', marginRight: isDesktop ? '0.7rem' : '1rem', flexDirection: isTab ? 'row' : 'column', alignItems: isTab ? '' : 'center', textAlign: isTab ? '' : 'center', }}
                >
                    {isTab && <>
                        <HeaderText style={{ textAlign: isTab ? '' : 'center' }}>Inbox</HeaderText>
                        <div style={{ marginTop: '1rem' }}>
                            <UserProfileSeller />
                        </div>
                    </>}

                    {!isTab && <>
                        <div style={{ marginLeft: 'auto', marginTop: '1rem' }}>
                            <UserProfileSeller />
                        </div>
                        <HeaderText style={{ textAlign: isTab ? '' : 'center' }}>{INBOX}</HeaderText>

                    </>}
                </FlexDiv>
                <div style={{ display: 'flex', flexDirection: isTab ? 'row' : 'column', marginLeft: isDesktop ? '0rem' : '1rem', marginTop: '1.37rem', marginRight: isDesktop ? '2rem' : '1.5rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: isTab ? '28rem' : '' }}>
                        {isTab &&
                            <div style={{ marginBottom: isTab ? '1rem' : '0rem', width: '96%' }}>
                                <ChatSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} onSearchSubmit={() => setFetchAgain(true)} />
                            </div>
                        }
                        {(!isTab && !chatListClicked) &&
                            <div style={{ marginBottom: isTab ? '1rem' : '0rem' }}>
                                <ChatSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} onSearchSubmit={() => setFetchAgain(true)} />
                            </div>
                        }
                        {isTab && <ChatList searchTerm={searchTerm} onSearchSubmit={() => setFetchAgain(true)} />}
                    </div>
                    {/* {isTab && (
                            <img src={VerticalDivider} alt=" " style={{ marginLeft: '2.75rem', marginRight: '2.44rem', height: '100vh' }} />
                        )} */}
                    <div style={{ marginTop: isTab ? '' : '2rem', width: '100%', marginLeft: '5rem' }}>
                        {isTab && socket && <ChatWindow listChatId={chatId} setListChatId={setChatId} firstName={firstName} lastName={lastName} chatsCopy={chatsCopy} setFetchAgain={setFetchAgain} setChatListClicked={setChatListClicked} />}
                    </div>
                </div>
                {!isTab && socket &&
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '0rem',
                        marginLeft: isDesktop ? '5.37rem' : '1rem',
                        marginRight: isDesktop ? '3.5rem' : '1.5rem',
                        height: '100vh',
                    }}>
                        {!chatListClicked &&
                            <div style={{ width: '100%', flex: 1, display: 'flex', justifyContent: 'center', marginTop: '3rem' }}>
                                <ChatList searchTerm={searchTerm} onSearchSubmit={() => setFetchAgain(true)} />
                            </div>
                        }
                        {chatListClicked &&
                            <div style={{ marginTop: '2rem', width: '100%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <ChatWindow listChatId={chatId} setListChatId={setChatId} firstName={firstName} lastName={lastName} setFetchAgain={setFetchAgain} chatsCopy={chatsCopy} setChatListClicked={setChatListClicked} />
                            </div>
                        }
                    </div>
                }
            </div>
        </>
    ) : (
        <>
        </>
    );
};

export default SellerInbox;
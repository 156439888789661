import React from "react";
import Modal from "react-modal";

const EmailSentModal = ({ isOpen, handleClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Email Sent Notification"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        },
        content: {
          width: "400px",
          height: "230px",
          margin: "auto",
          borderRadius: "0.75rem",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          padding: "1.5rem",
          zIndex: 1001,
          textAlign: "center",
        },
      }}
    >
      <h2 style={{ fontSize: "1.5rem", fontWeight: 600, marginBottom: "1rem" }}>
        Thank You for Your Purchase!
      </h2>
      <p style={{ fontSize: "1rem", marginBottom: "1.5rem" }}>
        An email with the purchase summary and instructions to access the tool
        has been sent to your email address.
      </p>
      <button
        onClick={handleClose}
        style={{
          padding: "0.75rem 1.5rem",
          borderRadius: "0.4375rem",
          background: "#3F0065",
          color: "#FFF",
          border: "none",
          fontFamily: "Poppins",
          fontWeight: 600,
          cursor: "pointer",
        }}
      >
        Close
      </button>
    </Modal>
  );
};

export default EmailSentModal;

import { React, useState } from "react";
import {
    Box,
    Typography,
    Button,
    Container,
    Stack
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ExploreOutlined, StorefrontOutlined, KeyboardArrowUp } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";


const HeaderSection = ({ backgroundImage }) => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();


    const handleEmailSubmit = () => {
        console.log("Email submitted:", email);
    };

    return (
        <Box
            sx={{
                position: 'relative',
                width: '99vw',
                height: '90vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                px: 2,
                overflowX: "hidden",
                boxSizing: "border-box",
            }}
        >
            {/* Background with gradient overlay */}
            <Box
                sx={{
                    position: 'absolute',
                    inset: 0,
                    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
                    backgroundColor: '#1a0b2e',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    // '&::before': {
                    //     content: '""',
                    //     position: 'absolute',
                    //     inset: 0,
                    //     background: 'linear-gradient(90deg, rgba(26,11,46,0.8) 0%, rgba(45,27,78,0.8) 100%)',
                    //     mixBlendMode: 'overlay'
                    // }
                }}
            />

            {/* Content container */}
            <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
                <Stack spacing={4} alignItems="center">
                    {/* Main heading */}
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: { xs: '2.5rem', md: '3.50rem' },
                            fontWeight: 'bold',
                            textAlign: 'center'
                        }}
                    >
                        Explore the #1 AI Marketplace for SMB Growth
                    </Typography>

                    {/* Subheading */}
                    <Typography
                        variant="h5"
                        sx={{
                            maxWidth: 'md',
                            textAlign: 'center',
                            mb: 4
                        }}
                    >
                        Discover a cutting-edge platform designed to help small and medium-sized
                        businesses unlock their full potential. Access an ecosystem of AI-powered
                        tools tailored to boost productivity, streamline operations, and drive
                        business growth.
                    </Typography>

                    {/* CTA Button */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: 4,
                            flexWrap: "wrap",
                        }}
                    >
                        {[
                            {
                                label: "Explore AI Tools for My Business",
                                icon: <ExploreOutlined />,
                                onClick: () => navigate("/signup")
                            },
                            {
                                label: "List My AI Tools on the Marketplace",
                                icon: <StorefrontOutlined />,
                                onClick: () => navigate("/signup-seller")
                            },
                        ].map((button, index) => (
                            <Button
                                key={index}
                                variant="contained"
                                startIcon={button.icon}
                                sx={{
                                    backgroundColor: "#A020F0",
                                    color: "white",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    padding: "10px 20px",
                                    borderRadius: "30px",
                                    fontFamily: "Poppins",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "#8A2BE2",
                                    },
                                }}
                                onClick={button.onClick}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </Box>


                    {/* Features */}
                    {/* <Stack
                        direction="row"
                        spacing={4}
                        flexWrap="wrap"
                        justifyContent="center"
                        sx={{ mt: 4 }}
                    >

                        <Stack direction="row" spacing={1} alignItems="center">
                            <CheckCircleIcon sx={{ color: '#A020F0' }} />
                            <Typography sx={{ fontSize: '1.5rem' }}>no credit card required</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <CheckCircleIcon sx={{ color: '#A020F0' }} />
                            <Typography sx={{ fontSize: '1.5rem' }}>AI solutions for every industry</Typography>
                        </Stack>
                    </Stack> */}
                </Stack>
            </Container>
        </Box>
    );
};

export default HeaderSection;